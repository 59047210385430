.flightCard__airlineLogo-J2RmN {
	display: none;
}

.flightCard__airlineNumber-3IOA0,
.flightCard__airlineName-35LCk {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.flightCard__flightBlock-3JTxk:first-child {
	display: none;
}

.flightCard__flightTitle-2TQZ- {
	display: none;
}

.flightCard__flightValue-aRJ1s {
	color: var(--text-base);
	font-size: 14px;
	font-weight: 700;
	line-height: 1.3;
}

.flightCard__flightValue-aRJ1s > svg {
	display: flex;

	color: var(--text-pale);
}

.flightCard__aircraftName-MhR5L {
	margin-right: 0;
	padding-right: 13px;

	border-right: 1px solid var(--line-separator);

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

.flightCard__info-3NUBr {
	top: 0;

	width: 18px;
	height: 18px;

	color: var(--text-pale);
}

.flightCard__airline-ZeuJF {
	width: 100%;
	max-width: 235px;
}

@media screen and (max-width: 600px) {
	.flightCard__aircraftName-MhR5L {
		margin-left: 0;
	}
}
