

.header__icon-c4CcM {
	display: none;
}

.header__number-3-oXB {
	display: block;

	background: var(--brand-1-1);
}

.option__included-14Psf {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC42NjY2IDguMDAwNjVDMTQuNjY2NiAxMS42ODI1IDExLjY4MTggMTQuNjY3MyA3Ljk5OTkyIDE0LjY2NzNDNC4zMTgwMiAxNC42NjczIDEuMzMzMjUgMTEuNjgyNSAxLjMzMzI1IDguMDAwNjVDMS4zMzMyNSA0LjMxODc1IDQuMzE4MDIgMS4zMzM5OCA3Ljk5OTkyIDEuMzMzOThDMTEuNjgxOCAxLjMzMzk4IDE0LjY2NjYgNC4zMTg3NSAxNC42NjY2IDguMDAwNjVaTTExLjgwNDcgNi40NzIwNkMxMi4wNjUgNi4yMTE3MSAxMi4wNjUgNS43ODk2IDExLjgwNDcgNS41MjkyNUMxMS41NDQzIDUuMjY4OSAxMS4xMjIyIDUuMjY4OSAxMC44NjE4IDUuNTI5MjVMNy4xMjI3MyA5LjI2ODM3TDUuMTM3OTkgNy4yODM2M0M0Ljg3NzY0IDcuMDIzMjggNC40NTU1MyA3LjAyMzI4IDQuMTk1MTggNy4yODM2M0MzLjkzNDgzIDcuNTQzOTggMy45MzQ4MyA3Ljk2NjA5IDQuMTk1MTggOC4yMjY0NEw3LjEyMjczIDExLjE1NEwxMS44MDQ3IDYuNDcyMDZaIiBmaWxsPSIjMTAzMTZFIi8+Cjwvc3ZnPgo=);
}

.option__money-31mDl {
	font-weight: 500;
}
