

.root-3twCT {
	display: flex;
	flex-direction: column;

	border-radius: 8px;

	background: var(--white);
	overflow: hidden;
}

.header-1zxEK {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	justify-content: space-between;
	align-items: center;

	min-height: 100px;

	padding: 0 16px;

	background: var(--brand-1-0);
}

.root_baggage-2T0H4 .header-1zxEK,
.root_baggageDelivery-1dJBA .header-1zxEK {
	background: var(--additional-baggage);
}

.root_meal-2-xFw .header-1zxEK {
	background: var(--additional-meal);
}

.root_transfer-bDZ4E .header-1zxEK,
.root_animal-13Wzz .header-1zxEK {
	background: var(--additional-aeroexpress);
}

.root_seats-1z6U- .header-1zxEK {
	background: var(--additional-seats);
}

.root_businessLounge-1h-0M .header-1zxEK {
	background: var(--additional-business-lounge);
}

.root_priorityBoarding-clG-I .header-1zxEK {
	background: var(--additional-priority-boarding);
}

.root_insurance-2YuEx .header-1zxEK {
	background: var(--additional-insurance);
}

.root_loyalty-TMFuc .header-1zxEK {
	background: var(--additional-ffp);
}

.root_capsuleHotel-2vrIS .header-1zxEK {
	background: var(--additional-capsule-hotel);
}

.root_sms-3vExf .header-1zxEK {
	background: var(--additional-sms);
}

.header__title-14rgV {
	flex: 1;
	align-items: center;

	color: var(--white);
	font-weight: 700;
	font-size: 16px;
}

.header__icon-2hPTA {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60%;
	max-height: 100px;
}

.header__icon-2hPTA img {
	width: auto;
	height: 100%;
	object-fit: contain;
}

.content-3aLtV {
	flex: 1;
	min-height: 100px;

	padding: 16px;

	color: var(--text-mid);
	font-size: 14px;
	line-height: 1.3;

	background: var(--white);
}

.root_loyalty-TMFuc .content-3aLtV {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}

.description-1p4DI {
	color: var(--text-mid);
	font-size: 14px;
	line-height: 1.3;

	white-space: break-spaces;
}

.description-1p4DI a {
	color: var(--text-link);
}

.footer-3GqbD {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 16px 16px;
	grid-gap: 8px;
	gap: 8px;
}

.footer__price-msFI1 {
	font-size: 16px;
	font-weight: 700;
	color: var(--text-base);
}

/* stylelint-disable */
.button-1WXwN {
}

.button_add-1qG8b {
}

.button_change-3SphY {
}
