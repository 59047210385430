.group__label-3YGEx {
	width: 100%;

	white-space: nowrap;

	color: #0033a0;
}

.options-ehVLR {
	border-radius: 15px;
}

button.allDirections__button-32uPD {
	border: 1px solid #bb2649;
	border-radius: 10px;

	color: #bb2649;

	background: transparent;
}

button.allDirections__button-32uPD:hover {
	color: var(--white);

	background: #bb2649;
}

.option__label-ZbGVd {
	color: var(--rm-brand-1-0);
}

.option__code-3ycMn {
	color: rgba(5, 22, 77, 0.4);
}

@media screen and (max-width: 390px) {
	.mobileDialog__input-2OMDq {
		font-size: 17px;
	}

	.mobileDialog__hint-3q5IA {
		font-size: 11px;
	}
}
