.flight__container-q4s_R {
	border: none;
	border-radius: 0;
}

.flight__segments-jlEHy {
	border-bottom: 1px solid var(--line-separator);
}

button.flight__price-1pkJE {
	border-radius: 4px;

	font-weight: 500;

	background: var(--brand-3--2);
}

button.flight__price-1pkJE:hover {
	background: var(--brand-3--2);
}

.flight__stopPoint-epUv6 {
	padding-bottom: 12px;
}

.flight_selectedFare-3lfZp {
	color: var(--brand-1-0);
}

.flight__priceClose-3dB_M {
	color: var(--brand-1-1);

	background-color: var(--brand-1-5);
}

.flight__selectedFarePriceWrapper-1_Ki7 {
	align-items: center;
}

.flight__selectedFarePrice-Pc6ZC {
	line-height: 1.1;
}

@media screen and (max-width: 600px) {
	.flight__container-q4s_R {
		padding-bottom: 1px;
	}
}
