.quickSearchForm__wrapper-hglDL {
	background-color: var(--brand-1-0);
}

.quickSearchForm__inner-1rOdN {
	max-width: 1100px;
	margin: 0 auto;

	box-shadow: none;
}

.summary__route-1inb0,
.summary__passengers-2xZ8a {
	color: var(--white);
}

.summary__routeIcon-1wsHB:before {
	content: '\2013';
}

.summary__routeIcon-1wsHB > svg {
	display: none;
}

.quickSearchForm__formWrapper-pWPqk {
	max-width: 1420px;
}

@media screen and (max-width: 600px) {
	.summary-1Qj0N {
		max-width: calc(100% - 50px);
	}
}
