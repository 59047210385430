

button.cancel-1s_ms,
button.cancel-1s_ms:focus {
	color: var(--text-base);

	background: var(--bg-fill);
}

button.cancel-1s_ms:hover {
	color: var(--text-base);

	background: var(--bg-elements);
}
