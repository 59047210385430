

.baggage__name-1NySq,
.baggage__size-2CuNe {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.baggageIcon__wrapper-2Kfd- {
	color: var(--brand-1-2);
}

.baggageIcon__size-3-Ksj {
	color: var(--text-light);
}
