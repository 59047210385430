

p.helperText-ffj0a[class*='error'] {
	position: relative;

	margin: 0 8px 0;

	color: var(--error-icon);
	font-size: 13px;

	transform: none;
}

li.option-1xamu {
	font-size: 16px;
	font-weight: 400;
	color: var(--text-mid);
}
