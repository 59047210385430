

.segmentTabs__wrapper-22U12 {
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

.segmentTabs__inner-22_56 {
	height: 69px;
}

.segmentTabs__title-3oExk {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

button.segmentTab__segment-1-noz {
	border-radius: 4px;

	background-color: var(--bg-elements);
}

button.segmentTab__segment-1-noz > span {
	display: flex;
	flex-direction: row-reverse;
}

button.segmentTab__segment-1-noz.segmentTab__segmentActive-dQyNd {
	background-color: var(--brand-1-0);
}

.segmentTab__checked-1gt1w {
	position: relative;
	top: auto;
	left: auto;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 21px;
	height: 21px;
	margin-left: 8px;

	color: var(--brand-3-0);

	transform: inherit;
}

.segmentTab__checked-1gt1w svg {
	width: 100%;
	height: 100%;
}

.segmentTab__checked-1gt1w circle {
	stroke-width: 0;
}

.segmentTab__count-2qzu0 {
	margin-left: 0;
	margin-right: 8px;
}
