

.specialEquipment__flightInfo-3bc2n {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3;
}

.specialEquipment__flightDirection-2h-y2 {
	color: var(--success-text);
}
