

.overweightBaggage__flightInfo-3Kt9r {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3;
}

.overweightBaggage__fligntDirection-3ZW9P {
	color: var(--success-text);
}
