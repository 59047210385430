

.title-MPzOb {
	color: var(--text-base);
}

.sub-1Kq02 {
	color: var(--text-mid);
}

.email-schP7 {
	color: var(--text-base);
}

button.button-1NZjX {
	color: white;
	font-size: 14px;
	font-weight: 700;

	background-color: var(--brand-1-0);
}

button.button-1NZjX:hover {
	background-color: var(--brand-1--1);
}
