

.additionalBaggage__header-pWMcQ {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.additionalBaggage__promoMessage-mMLVp {
	border: 1px solid var(--success-stroke-hover);

	color: var(--success-text);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;

	background: var(--success-bg);
}
