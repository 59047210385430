.container-2gx3A {
	display: none;
}

button.button__rules-1FucT {
	color: var(--brand-1-1);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.modal__header-R1R2E {
	padding: 17px 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;

	background: var(--white);
}

@media screen and (max-width: 600px) {
	.container-2gx3A {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 12px;
	}

	.modal__close-Ndmc- {
		top: 12px;
		right: 12px;
	}
}
