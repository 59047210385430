.rmRefund__header-2F7JN {
	margin-bottom: 40px;
}

.rmRefund__breadcrumb-zTpYL {
	font-size: 15px;
	line-height: 24px;
	color: var(--brand-1--1);

	background: var(--brand-1-5);
}

.rmRefund__breadcrumbClose-a1KjB path {
	color: var(--text-pale);
}

.rmRefund__stepTitle-WEEv0 {
	/* padding-top: 40px; */
	margin-bottom: 28px;
}

.rmRefund__stepTitle_icon-3pKoy {
	background: var(--brand-1-0);
}

h6.rmRefund__stepTitle_text-2A2VF {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

div.rmRefund__paper-31f36 {
	box-shadow: var(--shdw-card);
}

button.rmRefund__passenger-2YAIg {
	padding: 13px 27px;

	border-radius: 0;

	color: var(--text-base);

	background: var(--bg-fill);
}

.rmRefund__passengerLabel-1m1te {
	color: var(--text-base);
	font-size: 15px;
}

span.rmRefund__passengerCheckbox-1v2BI {
	color: var(--text-pale);
}

span.rmRefund__passengerCheckbox-1v2BI.rmRefund__passenger_active-3YxWw {
	color: var(--brand-1-0);
}

.rmRefund__passengerLabel-1m1te.rmRefund__passenger_active-3YxWw {
	color: var(--brand-1-0);
}

button.rmRefund__passenger-2YAIg.rmRefund__passenger_active-3YxWw {
	background: var(--brand-1-5);
}

button.rmRefund__stepsButton-2BKJn {
	--active: var(--brand-3--1);
	--active-30: var(--brand-3-0);
	--active-50: var(--brand-3--2);
	min-width: 100px;
	height: 40px;
	padding: 0 20px;

	border-radius: 4px;

	font-size: 15px;
	line-height: 40px;
	font-weight: 500;
}

button.rmRefund__stepsButton-2BKJn:hover,
button.rmRefund__stepsButton-2BKJn:focus {
	box-shadow: none;
}

.rmRefund__reasons-1jrq7 .rmRefund__reason-2480V {
	position: relative;

	padding: 11px 0;

	border-bottom: 0;
}

.rmRefund__reasons-1jrq7 .rmRefund__reason-2480V:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 11px;

	display: block;
	height: 1px;

	background-color: var(--line-separator);
	content: '';
}

label.rmRefund__reasonLabel-oAhHq {
	color: var(--text-base);
}

label.rmRefund__reasonLabel-oAhHq.rmRefund__radio_active-1huwd {
	color: var(--brand-1-0);
}

label.rmRefund__groupTitle-18Dce {
	display: block;
	padding: 20px 0;

	border-bottom: 1px solid var(--line-separator);

	color: var(--text-base);
	font-size: 16px;
}

span.rmRefund__radio-rcL6D {
	color: var(--text-pale);
}

span.rmRefund__radio-rcL6D.rmRefund__radio_active-1huwd {
	color: var(--brand-1-0);
}

span.rmRefund__requestCheckbox-3LX4z {
	color: var(--text-pale);
}

span.rmRefund__requestCheckbox-3LX4z.rmRefund__request_active-1WQre {
	color: var(--brand-1-0);
}

label.rmRefund__requestLabel-2FUbq {
	color: var(--text-base);
}

label.rmRefund__requestAgree-1sN7Q {
	color: var(--text-light);
}

label.rmRefund__requestAgree-1sN7Q a {
	color: var(--text-light);
}

.rmRefund__priceTotal-HuUT4 {
	color: var(--text-base);
}

.rmRefund__modalContent-39Ydo {
	color: var(--text-base);
}

button.rmRefund__modalButton-2NECI {
	--cabinet-action: var(--brand-3--1);
	--cabinet-action--hover: var(--brand-3-0);
	border-radius: 4px;
}

.rmRefund__note-3Hgwf {
	color: var(--text-base);
}

.rmRefund__noteText-1iJxG {
	color: var(--text-mid);
}

.rmRefund__noteIcon-aXq9V.rmRefund__noteGray-pJGtM {
	color: var(--text-pale);
}

div.ticket__price-32rTE {
	max-width: 100%;
}

div.status__processing-1WK96,
div.status__waiting-2qA-Y {
	background: var(--error-icon);
}

@media screen and (max-width: 600px) {
	.rmRefund__wrapper-18MxS {
		position: relative;
	}

	.rmRefund__wrapper-18MxS:before {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;

		display: block;
		height: 56px;

		background: var(--white);
		content: '';
	}

	.rmRefund__header-2F7JN {
		margin: 0 0 20px;
		padding: 18px 0;

		background: var(--white);
	}

	.rmRefund__title-2vUOO {
		font-size: 16px;
	}

	.rmRefund__close-zXjN4 {
		right: 0;

		transform: none;
	}

	.rmRefund__close-zXjN4 svg {
		transform: scale(0.8);
	}

	.rmRefund__mobileStep-3oSPJ {
		font-size: 15px;

		background: var(--brand-1-0);
	}

	.rmRefund__priceTotal-HuUT4 {
		padding: 0 16px;
	}

	.rmRefund__priceRow-2aFxV {
		padding: 0 16px;
	}

	div.rmRefund__paper-31f36 {
		padding-top: 20px;
		padding-bottom: 20px;

		box-shadow: none;
	}

	div.rmRefund__price-2P7dI.rmRefund__paper-31f36 .rmRefund__priceTotal-HuUT4 {
		padding: 0;
	}

	div.rmRefund__price-2P7dI.rmRefund__paper-31f36 .rmRefund__priceRow-2aFxV {
		padding: 0;
	}

	.rmRefund__mobileStepHeader-2AO10 {
		background: var(--brand-1-0);
	}

	div.rmRefund__passengerLabel-1m1te {
		border-radius: 0;
	}

	button.rmRefund__passenger-2YAIg {
		padding-right: 0;
		padding-left: 0;

		background: transparent;
	}

	.rmRefund__passengerLabel-1m1te:not(:last-of-type) {
		margin-bottom: 0;

		border-bottom: 1px solid var(--line-separator);
	}

	.rmRefund__passengerLabel-1m1te:first-child button.rmRefund__passenger-2YAIg {
		padding-top: 0;
	}

	.rmRefund__passengerLabel-1m1te:last-child button.rmRefund__passenger-2YAIg {
		padding-bottom: 0;
	}

	button.rmRefund__passenger-2YAIg.rmRefund__passenger_active-3YxWw {
		background: transparent;
	}

	button.rmRefund__passenger-2YAIg > span {
		background: transparent;
	}

	button.rmRefund__passenger-2YAIg > span * {
		background: transparent;
	}
}
