

.context-3SXCy.info-2fEdw {
	border: 1px solid var(--brand-1-3);
	border-radius: 4px;

	background: var(--brand-1-5);
}

.context-3SXCy.warning-38tNB {
	border: 1px solid var(--warning-icon);

	background-color: var(--warning-bg);
}

.icon-2-NBv {
	color: var(--text-pale);
}

.title-3FW4C {
	font-size: 16px;
	font-weight: 700;
	color: var(--text-base);
}

.text-2bwQZ {
	font-size: 16px;
	font-weight: 500;
	color: var(--text-mid);
}

.context-3SXCy.warning-38tNB .text-2bwQZ {
	color: var(--text-mid);
}

.context-3SXCy.warning-38tNB .icon-2-NBv {
	color: var(--text-pale);
}

button.action-1fNMm {
	padding: 6px 16px;

	border: none;
	border-radius: 4px;

	color: white;

	background-color: var(--brand-3--1);
}

button.action-1fNMm:hover {
	background-color: var(--brand-3-0);
}

button.action-1fNMm > svg {
	display: none;
}
