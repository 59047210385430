

button.counter__button-1RC31 {
	color: var(--text-base);

	background-color: var(--brand-1-5);
}

button.counter__button-1RC31:not(.counter__buttonDisabled-3JPIg):hover {
	color: var(--text-base);

	background-color: var(--brand-1-4);
}

button.counter__button-1RC31.counter__buttonDisabled-3JPIg {
	color: var(--text-pale);

	background-color: var(--bg-fill);
}

.counter__value-2X8lR {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}
