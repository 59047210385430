.rmExchange__header-3AlVh {
	padding: 40px 0;
}

.rmExchange__headerText-1jsNk {
	font-size: 31px;
	line-height: 1.3;
	font-weight: 700;
	color: var(--text-base);
}

.rmExchange__close-38xNM {
	top: 0;
	bottom: 0;

	width: 32px;
	height: 32px;
	margin: auto 0;

	color: var(--text-light);

	background: var(--bg-fill);
}

.rmExchange__chip-1yFSY {
	margin-bottom: 0;

	color: var(--brand-1--1);
	font-weight: 500;
	font-size: 15px;
	line-height: 1.3;

	background: var(--brand-1-5);
}

.rmExchange__chipClose-2Pq_6 {
	color: var(--text-pale);
}

.rmExchange__commonHeader-LHTNf {
	color: var(--text-base);
}

.rmExchange__commonIcon-3DEhe {
	background: var(--brand-1-0);
}

button.rmExchange__commonButton-1ZaA6:disabled {
	color: var(--gray30);

	background-color: var(--gray10);
	cursor: not-allowed;
}

.rmExchange__choiceContainer-3h7QK {
	box-shadow: var(--shdw-card);
}

label.rmExchange__choiceLabel-2UWS1 {
	margin-left: 0;

	color: var(--text-base);

	background: var(--bg-fill);
}

span.rmExchange__choiceCheckbox-3yd37 {
	color: var(--text-pale);
}

label.rmExchange__choiceLabel-2UWS1.rmExchange__choiceLabel_selected-2QhdR {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

span.rmExchange__choiceCheckbox-3yd37.rmExchange__choiceCheckbox_selected-2B8bH {
	color: var(--brand-1-0);
}

.rmExchange__datepickerWrapper-cxHJ6 {
	background-color: var(--brand-1-1);
}

.rmExchange__flightsHeader-29T9t {
	color: var(--text-base);
}

.rmExchange__flightsHeader-29T9t svg {
	color: var(--brand-1-0);
}

.rmExchange__flightsLeg-2F4q4 {
	background: var(--brand-1-0);
}

button.rmExchange__flightsCalendar-39493 {
	padding: 0;

	border: 0;
	border-radius: 0;

	color: var(--brand-1-0);

	background: transparent;
}

button.rmExchange__flightsCalendar-39493:hover {
	background: transparent;
}

div.rmExchange__flightsChange-2KAnN {
	color: var(--brand-1-0);
}

.rmExchange__flightsHeaderName-2ry5E {
	color: var(--text-base);
}

.rmExchange__flightsHeaderName-2ry5E svg circle {
	fill: var(--brand-1-0);
}

.rmExchange__flightsCreateControls-2m_aY {
	color: var(--text-base);
}

.rmExchange__flightsCreateControls-2m_aY a {
	color: var(--text-base);
}

span.rmExchange__flightsCreateButton-3wdZA {
	color: var(--text-pale);
}

span.rmExchange__flightsCreateButton-3wdZA.rmExchange__flightsCreateButton_checked-VIfZZ {
	color: var(--brand-1-0);
}

.rmExchange__flightsDisaclaimer-qJ-s0 {
	color: var(--text-light);
}

.rmExchange__dialogPaper-27ym8 svg {
	margin-bottom: 0;
}

.rmExchange__dialogPaper-27ym8 > svg {
	margin-bottom: 20px;
}

button.rmExchange__flightsCalendar-39493 svg {
	order: 2;
	margin-right: 0;
	margin-left: 6px;

	opacity: 0.6;
}

.rmExchange__requestFormHeader-2S8Nn {
	color: var(--text-base);
}

.rmExchange__requestFormCreated-1HDcJ {
	color: var(--text-light);
}

.calendar__monthsList-zbEQj {
	grid-gap: 28px;
	gap: 28px;
}

div.calendar__day-2SWnW {
	padding: 0;

	border-right: 2px solid var(--white);
	border-left: 2px solid var(--white);
	border-radius: 4px;
}

.calendar__dayInside-3JkMF {
	border-radius: 4px;
}

.selectedFlights__flightContainer-24q5n {
	margin-bottom: 1px;
}

.selectedFlights__charge-1X7eZ {
	position: relative;

	margin: 0;
	padding: 20px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 15px;
	line-height: 1.3;
}

.selectedFlights__charge-1X7eZ:not(:last-of-type) {
	border-bottom: none;
}

.selectedFlights__charge-1X7eZ:not(:last-of-type):before {
	position: absolute;
	bottom: 0;

	width: calc(100% - 40px);
	height: 1px;

	background: var(--line-separator);
	content: '';
}

.selectedFlights__containerFlat-20lto .selectedFlights__charge-1X7eZ:not(:last-of-type) {
	border-bottom: none;
}

.selectedFlights__containerFlat-20lto .selectedFlights__charge-1X7eZ:not(:last-of-type):before {
	position: absolute;
	bottom: 0;

	width: calc(100% - 40px);
	height: 1px;

	background: var(--line-separator);
	content: '';
}

.selectedFlights__chargeMoney-zuzZQ {
	font-size: 15px;
	font-weight: 400;
	line-height: 1.3;
}

.selectedFlights__price-2vj2z {
	border-left: 1px dashed var(--line-separator);

	color: var(--text-mid);
}

.selectedFlights__total-3LTAH {
	align-items: center;
	width: 100%;

	color: var(--text-base);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;
}

.selectedFlights__totalMoney-2-PdS {
	margin-left: auto;

	font-weight: 700;
	font-size: 31px;
	line-height: 1.3;
}

div.calendar__dayHighlight-1q7B- {
	background: transparent;
}

.calendar__daySelected-1KDIW .calendar__dayInside-3JkMF {
	border-radius: 4px;

	color: var(--white);
}

div.calendar__dayHighlight-1q7B- .calendar__dayInside-3JkMF {
	color: var(--text-base);
}

div.calendar__dayHighlight-1q7B-.calendar__daySelected-1KDIW .calendar__dayInside-3JkMF {
	border-radius: 4px;

	color: var(--white);
}

@media (max-width: 1024px) {
	.inner__container-1ybnc {
		padding: 0 12px;
	}

	.rmExchange__flightsHeaderName-2ry5E svg {
		display: flex;
		width: 28px;
		height: 28px;
	}

	.rmExchange__header-3AlVh {
		margin: 0;
		padding: 0;

		background: transparent;
	}

	.rmExchange__headerText-1jsNk {
		margin: 0 0 20px;
		padding: 40px 20px;

		background: var(--white);
	}

	.rmExchange__close-38xNM {
		top: 40px;
		right: 20px;
		bottom: auto;

		transform: none;
	}

	.rmExchange__selectionsWrapper-Pqyby {
		margin-top: 12px;
		margin-bottom: 20px;
		padding: 0 12px;
	}

	.rmExchange__chip-1yFSY {
		color: var(--white);

		background: var(--brand-1-0);
	}

	.rmExchange__chip-1yFSY:not(:first-child) {
		border-top-color: #4c70bd;
	}

	.rmExchange__chipClose-2Pq_6 {
		color: var(--white);

		background: rgba(255, 255, 255, 0.2);
	}

	.calendar__monthsList-zbEQj {
		grid-gap: 40px;
		gap: 40px;
	}
}

@media screen and (max-width: 600px) {
	.rmExchange__header-3AlVh {
		margin: 0;
		padding: 0;

		background: transparent;
	}

	.rmExchange__headerText-1jsNk {
		margin: 0 0 12px;
		padding: 18px 12px;

		font-size: 16px;

		background: var(--white);
	}

	.rmExchange__close-38xNM {
		right: 12px;
		top: 12px;
	}

	.rmExchange__close-38xNM svg {
		transform: scale(0.8);
	}

	.rmExchange__commonHeader-LHTNf {
		color: var(--white);

		background: var(--brand-1-0);
	}

	.rmExchange__choiceContainer-3h7QK {
		border-radius: 0 0 8px 8px;

		box-shadow: none;
	}

	label.rmExchange__choiceLabel-2UWS1 {
		background: transparent;
	}

	label.rmExchange__choiceLabel-2UWS1.rmExchange__choiceLabel_selected-2QhdR {
		background-color: transparent;
	}

	.rmExchange__flightsCreateSubmit-oDtZr {
		margin-top: 12px;
	}

	.rmExchange__flightsRoute-2kZU3 {
		background: var(--brand-1--1);
	}

	button.rmExchange__flightsCalendar-39493 {
		width: 100%;

		color: var(--white);

		background: var(--brand-1-1);
	}

	.selectedFlights__flightContainer-24q5n .selectedFlights__flights-Oaj8U > div {
		margin-bottom: 0;

		border: none;
	}

	.selectedFlights__charge-1X7eZ:not(:last-of-type):before {
		width: calc(100% - 24px);
	}

	.selectedFlights__containerFlat-20lto .selectedFlights__charge-1X7eZ:not(:last-of-type):before {
		width: calc(100% - 24px);
	}

	.selectedFlights__charge-1X7eZ {
		padding: 12px;
	}

	.selectedFlights__price-2vj2z {
		margin-bottom: 8px;

		border-left: none;
	}
}
