.fareGroup__fareGroupWhite-3kksN {
	height: 100%;
}

.fareGroup__fareGroupWhite-3kksN > div:first-of-type {
	margin-bottom: 16px;
}

.fareGroup__fareGroupWhite-3kksN .fareGroup__optionTitle-2DrQc {
	text-overflow: ellipsis;
	overflow: hidden;
}

.fareGroup__fareGroupWhite-3kksN .fareGroup__optionIcon-1IYoZ,
.fareGroup__fareGroupWhite-3kksN .fareGroup__optionTitle-2DrQc {
	color: var(--text-mid);
}

.fareGroup__fareGroupWhite-3kksN .fareGroup__optionValue-36SJ4 {
	color: var(--brand-1-0);
}

.fareGroup__fareGroupWhite-3kksN .fareGroup__optionUnavailable-1iizT .fareGroup__optionIcon-1IYoZ,
.fareGroup__fareGroupWhite-3kksN .fareGroup__optionUnavailable-1iizT .fareGroup__optionTitle-2DrQc,
.fareGroup__fareGroupWhite-3kksN .fareGroup__optionUnavailable-1iizT .fareGroup__optionValue-36SJ4 {
	color: var(--text-pale);
}

.fareGroupGrid__option-z7hXv {
	border-radius: 2px;
	border: 1px solid var(--brand-1-4);
}

.fareGroupGrid__paid-3wkbD {
	border: 1px solid var(--line-separator);

	color: var(--brand-1-3);

	opacity: 1;
	background-color: var(--white);
}

.fareGroupGrid__option-z7hXv.fareGroupGrid__paid-3wkbD .fareGroupGrid__icon-3Oxwk svg {
	color: var(--brand-1-3);
}

svg.recommendedBadge__badge-3pDJa {
	color: var(--brand-3--1);
}

.fareGroupGrid__recommended-1FAID {
	border: 2px solid var(--brand-3--1);
}

.fareGroupGrid__upgradeOfferText-quazX:after {
	display: none;
}

.fareGroupGrid__upgradeOfferChoose-3r3dE {
	font-weight: 700;
}

@media screen and (max-width: 600px) {
	.fareGroup__fareGroup-oeScR {
		width: calc(100% - 10px);
		min-width: unset;
	}
}
