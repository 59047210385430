

.root-19PY7 .header-2-kEp {
	background: var(--brand-1-1);
}

.root-19PY7:not(.root_loyalty-3AW24) button.button_add-3XCFA {
	width: 34px;
	min-width: 34px;
	height: 34px;
	min-height: 34px;

	padding: 2px;

	font-size: 0;
}

.root-19PY7:not(.root_loyalty-3AW24) button.button_add-3XCFA:not(.button_loading-2O4Ct):after {
	content: '';

	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xNi42MjUgMTEuMzc1SDExLjM3NVYxNi42MjVIOS42MjVWMTEuMzc1SDQuMzc1VjkuNjI1SDkuNjI1VjQuMzc1SDExLjM3NVY5LjYyNUgxNi42MjVWMTEuMzc1WiIgZmlsbD0id2hpdGUiPjwvcGF0aD4KPC9zdmc+Cg==) no-repeat center;
}
