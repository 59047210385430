

.mobileSelectedFare__featureTitle-1_ed4 {
	color: var(--text-base);
	font-weight: 700;
	line-height: 1.3;
}

.mobileSelectedFare__featureText-3guCI {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.mobileSelectedFare__featuresInfo-UluRH {
	color: var(--text-base);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.mobileSelectedFare__featureIcon-VsPX4 {
	color: var(--brand-1-0);
}

.mobileSelectedFare__dialogHeader-ow6an {
	border-bottom: none;

	color: var(--text-base);

	background-color: var(--white);
}

.mobileSelectedFare__dialogHeader-ow6an > div:first-child {
	padding: 0 12px;
}
