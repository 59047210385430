/* stylelint-disable */
.rmSearchForm-1PeWh {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;

	background-color: var(--brand-1-0-opacity);
}

.rmSearchForm-1PeWh input {
	outline: none;
}

.rmSearchForm__row-3xSHD {
	--input-height: 72px;

	max-width: 1420px;
}

.rmSearchForm__row-3xSHD:empty {
	display: none;
}

.rmSearchForm__segments-2BIIt {
	margin-right: 0;

	border-right: none;
}

.rmSearchForm__segments_multiCity-2tL9G {
	border-right: 0;
}

.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__segment-2sceQ {
	position: relative;
}

.rmSearchForm__cell-aIETQ:not(:last-of-type) {
	margin-right: 0;

	border-right: none;
}

.rmSearchForm__input-1UnsN {
	border-radius: 0;

	background-color: var(--white);
}

.rmSearchForm__input-1UnsN:-ms-input-placeholder {
	color: var(--text-pale);

	opacity: 1;
}

.rmSearchForm__input-1UnsN::placeholder {
	color: var(--text-pale);

	opacity: 1;
}

.rmSearchForm__input-1UnsN.location__inputWithoutPlaceholder-14OgJ:-ms-input-placeholder {
	color: transparent;
}

.rmSearchForm__input-1UnsN.location__inputWithoutPlaceholder-14OgJ::placeholder {
	color: transparent;
}

/* .rmSearchForm__input::-webkit-input-placeholder { */

/*	color: var(--text-pale); */

/* } */

/* .rmSearchForm__input::-moz-placeholder { */

/*	color: var(--text-pale); */

/* } */

/* .rmSearchForm__input:-ms-input-placeholder { */

/*	color: var(--text-pale); */

/* } */
.rmSearchForm__cell-aIETQ:first-child .rmSearchForm__input-1UnsN {
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.rmSearchForm__input-1UnsN:not(:focus):not(.rmSearchForm__input_focused-jxyCW):hover {
	background-color: var(--white);
}

.rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__cell-aIETQ {
	flex-basis: 33.33%;
}

.rmSearchForm__switcher-1G4-B {
	width: 24px;
	height: 24px;
	right: -12px;

	color: var(--rm-brand-1-0);

	border-radius: 4px;
	opacity: 1;
}

.rmSearchForm__switcher-1G4-B:hover {
	color: var(--brand-1-0);
}

.rmSearchForm__switcher-1G4-B svg path {
	fill: currentColor;
}

.rmSearchForm__startSearch-oqQAK {
	display: flex;

	flex-basis: 44px;
	justify-content: center;
	align-items: center;

	padding: 0 20px 0 0;

	border-radius: 0 16px 16px 0;

	background: var(--white);
}

.rmSearchForm__startSearchIconMode-sNa4M.rmSearchForm__startSearch-oqQAK button,
.rmSearchForm__startSearchMultiCity-3dV4Y.rmSearchForm__startSearch-oqQAK button {
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	padding: 0;

	border: 1px solid #bb2649;
	border-radius: 50%;

	color: #bb2649;

	background: transparent;
}

.rmSearchForm__startSearchMultiCity-3dV4Y.rmSearchForm__startSearch-oqQAK button {
	position: relative;

	color: transparent;
}

.rmSearchForm__startSearchMultiCity-3dV4Y.rmSearchForm__startSearch-oqQAK button:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 50%;
	height: 50%;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjQyNiAxMi43Nzk4SDEzLjUxNzhMMTMuMTk1OSAxMi40Njk0QzE0LjMyMjUgMTEuMTU4OCAxNS4wMDA4IDkuNDU3MzYgMTUuMDAwOCA3LjYwNjQ3QzE1LjAwMDggMy40NzkzMiAxMS42NTU0IDAuMTMzOTExIDcuNTI4MjIgMC4xMzM5MTFDMy40MDEwNyAwLjEzMzkxMSAwLjA1NTY2NDEgMy40NzkzMiAwLjA1NTY2NDEgNy42MDY0N0MwLjA1NTY2NDEgMTEuNzMzNiAzLjQwMTA3IDE1LjA3OSA3LjUyODIyIDE1LjA3OUM5LjM3OTEyIDE1LjA3OSAxMS4wODA2IDE0LjQwMDcgMTIuMzkxMSAxMy4yNzQxTDEyLjcwMTUgMTMuNTk2VjE0LjUwNDJMMTguNDQ5NyAyMC4yNDA4TDIwLjE2MjYgMTguNTI3OUwxNC40MjYgMTIuNzc5OFpNNy41MjgyMiAxMi43Nzk4QzQuNjY1NjYgMTIuNzc5OCAyLjM1NDkxIDEwLjQ2OSAyLjM1NDkxIDcuNjA2NDdDMi4zNTQ5MSA0Ljc0MzkgNC42NjU2NiAyLjQzMzE2IDcuNTI4MjIgMi40MzMxNkMxMC4zOTA4IDIuNDMzMTYgMTIuNzAxNSA0Ljc0MzkgMTIuNzAxNSA3LjYwNjQ3QzEyLjcwMTUgMTAuNDY5IDEwLjM5MDggMTIuNzc5OCA3LjUyODIyIDEyLjc3OThaIiBmaWxsPSIjQkIyNjQ5Ii8+Cjwvc3ZnPgo=) no-repeat center / contain;
	transform: translate(-50%, -50%);
}

.rmCurrency-uQOVL {
	margin-right: 0;
}

button.rmCurrency__selector-10b5y {
	border-radius: 0;

	color: var(--text-base);
	font-weight: 700;
}

button.rmCurrency__selector-10b5y,
.rmCurrency-uQOVL button.rmCurrency__selector-10b5y:focus,
.rmCurrency-uQOVL button.rmCurrency__selector-10b5y:not(:focus):hover {
	background-color: var(--white);
}

.currency__selectorInner-1lnfn span:first-of-type:after {
	content: ' ';
}

span.currency__icon-33mBD {
	color: var(--text-pale);
}

span.currency__icon-33mBD.currency__icon_flipped-tYfhn {
	color: var(--text-pale);
}

.rmCurrency__item-3S397 {
	color: var(--rm-brand-1-0);
	border-radius: 10px;
}

.rmCurrency__item-3S397:hover {
	color: var(--rm-brand-1-0);
	background: rgba(5, 22, 77, 0.05);
}

div.rmSearchForm__valueWrapper-3wjRU {
	color: var(--text-light);
}

.rmSearchForm__valueWrapper_focused-1TbsV {
	border-radius: 0;
}

.rmSearchForm__value-1uXDc {
	color: #3b4759;
	font-size: 18px;
	font-weight: 500;
}

.rmSearchForm__code-2neTK {
	margin-left: auto;

	color: rgba(82, 98, 124, 0.8);
	font-weight: 500;
	font-size: 12px;

	opacity: 1;
}

.rmSearchForm__placeholder-3MXUJ {
	color: rgba(5, 22, 77, 0.6);
}

.rmSearchForm__cell-aIETQ:first-child {
	position: relative;
}

.rmSearchForm__cell-aIETQ:first-child:before {
	top: 0;

	border-radius: 0 0 2px 2px;
}

.rmSearchForm__cell-aIETQ:first-child:after {
	bottom: 0;

	border-radius: 2px 2px 0 0;
}

.rmSearchForm__cell-aIETQ:first-child:before,
.rmSearchForm__cell-aIETQ:first-child:after {
	position: absolute;
	right: -1px;

	z-index: 2;

	width: 2px;
	height: 30%;

	background: #52627c;
	content: '';
}

.rmSearchForm__cell-aIETQ:first-child .rmSearchForm__valueWrapper_focused-1TbsV {
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.rmSearchForm__dates-3YbNq {
	padding: 0 4px;

	border-radius: 0;

	font-weight: 700;

	font-size: 16px;

	background: var(--white);
}

.rmSearchForm__dates-3YbNq:before {
	position: absolute;
	top: 4px;
	right: 4px;
	bottom: 4px;
	left: 4px;

	z-index: 1;

	border: 2px solid #05164d;
	border-radius: 15px;

	pointer-events: none;
	content: '';
}

/*.rmSearchForm__segment_withMultiCity .rmSearchForm__dates {*/
/*	border-radius: 0 4px 4px 0;*/
/*}*/

.rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__dates-3YbNq {
	border-radius: 0 16px 16px 0;
}

.rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__dates-3YbNq .rmSearchForm__to_focused-3IuPF {
	border-radius: 0 10px 10px 0;
}

.rmSearchForm__dates_focused-1i-aJ,
.rmSearchForm__dates-3YbNq:not(.rmSearchForm__dates_focused-1i-aJ):hover {
	background: var(--white);
}

.rmSearchForm__dow-3QMxZ {
	color: #52627c;
	font-weight: 500;

	opacity: 1;
}

.rmSearchForm__dow-3QMxZ:before {
	color: var(--text-base);
}

.rmSearchForm__returnDelete-2IFsL {
	right: 20px;
}

.rmSearchForm__returnDelete-2IFsL svg {
	width: 24px;
	height: 24px;
}

.rmSearchForm__dates_focused-1i-aJ .rmSearchForm__returnDelete-2IFsL {
	width: 24px;
	height: 34px;
	background: #f4f4f4;
	color: #bb2649;
	display: flex;
	align-items: center;
}

.rmSearchForm__dates_focused-1i-aJ .rmSearchForm__returnDelete-2IFsL:hover {
	color: #bb2649;
	font-size: 16px;
	font-weight: 500;

	opacity: 1;
}

.rmSearchForm__to-2pQ1f,
.rmSearchForm__back-2hP5G {
	color: #52627c;
	font-weight: 500;
}

.rmSearchForm__to-2pQ1f:before,
.rmSearchForm__dates_focused-1i-aJ .rmSearchForm__to-2pQ1f:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyNCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTIuNjY2N0g1LjMzMzMzVjE1LjMzMzNIOFYxMi42NjY3Wk0xMy4zMzMzIDEyLjY2NjdIMTAuNjY2N1YxNS4zMzMzSDEzLjMzMzNWMTIuNjY2N1pNMTguNjY2NyAxMi42NjY3SDE2VjE1LjMzMzNIMTguNjY2N1YxMi42NjY3Wk0yMS4zMzMzIDMuMzMzMzJIMjBWMC42NjY2NTZIMTcuMzMzM1YzLjMzMzMySDYuNjY2NjdWMC42NjY2NTZINFYzLjMzMzMySDIuNjY2NjdDMS4xODY2NyAzLjMzMzMyIDAuMDEzMzMzMyA0LjUzMzMyIDAuMDEzMzMzMyA1Ljk5OTk5TDAgMjQuNjY2N0MwIDI2LjEzMzMgMS4xODY2NyAyNy4zMzMzIDIuNjY2NjcgMjcuMzMzM0gyMS4zMzMzQzIyLjggMjcuMzMzMyAyNCAyNi4xMzMzIDI0IDI0LjY2NjdWNS45OTk5OUMyNCA0LjUzMzMyIDIyLjggMy4zMzMzMiAyMS4zMzMzIDMuMzMzMzJaTTIxLjMzMzMgMjQuNjY2N0gyLjY2NjY3VjkuOTk5OTlIMjEuMzMzM1YyNC42NjY3WiIgZmlsbD0iIzA1MTY0RCIvPgo8L3N2Zz4K) no-repeat 50% 50% / contain;
}

.rmSearchForm__to_focused-3IuPF,
.rmSearchForm__back_focused-1XfrZ {
	color: var(--text-base);

	background: var(--bg-white);
}

.rmSearchForm__back-2hP5G:before,
.rmSearchForm__dates_focused-1i-aJ .rmSearchForm__back-2hP5G:before {
	border-left-color: rgba(82, 98, 124, 0.6);

	opacity: 1;
}

.rmSearchForm__dates_focused-1i-aJ .rmSearchForm__to-2pQ1f:after {
	display: none;
}

.rmSearchForm__dates_placeholder-23cqH {
	color: var(--text-light);
}

.rmSearchForm__passengers-G7DAd {
	margin-right: 0;
}

.rmSearchForm__passengers_isMultiCity-3oKk- {
	flex-basis: 33.33%;
}

.rmSearchForm__wrapper-24Ftr {
	border-right: none;
}

button.rmSearchForm__button-2umys {
	background: var(--brand-3--1);
}

button.rmSearchForm__selector-3ii3k {
	border-radius: 0;
	color: var(--text-base);
	font-weight: 700;

	background: var(--white);
}

.rmSearchForm__passengers_isMultiCity-3oKk-.rmSearchForm__passengers_withCurrency-1RwQd button.rmSearchForm__selector-3ii3k {
	border-radius: 16px 0 0 16px;
}

button.rmSearchForm__selector-3ii3k:not(:focus):not(.rmSearchForm__selector_focused-26thc):hover {
	background-color: var(--bg-fill);
}

.rmSearchForm__passengers-G7DAd button.rmSearchForm__selector-3ii3k:not(:focus):not(.rmSearchForm__selector_focused-26thc):hover {
	background-color: var(--white);
}

button.rmSearchForm__selector_focused-26thc {
	background-color: var(--bg-white);
}

.rmSearchForm__arrowIcon-tpafa {
	color: var(--text-pale);
	margin-left: 5px;
}

.rmSearchForm__arrowIcon_flipped-Fa5H4 {
	color: var(--text-pale);
}

.rmSearchForm__arrowIcon_flipped-Fa5H4 svg path {
	fill: currentColor;
}

.rmSearchForm__passengersMenu-2B2Xy {
	width: 280px;

	border-radius: 15px;

	box-shadow: var(--shdw-dropdown);
}

.rmSearchForm__mobileTitle-1x1GW {
	padding-bottom: 20px;

	border-bottom: 1px solid #d9d9d9;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.rmSearchForm__controls_multiCity-1lD5N .rmCurrency-uQOVL {
	flex-basis: 33.33%;
}

.rmSearchForm__controls_multiCity-1lD5N .rmSearchForm__startSearch-oqQAK {
	flex-basis: 33.33%;
	justify-content: flex-end;

	margin-left: auto;
}

.rmSearchForm__actions-1glVz {
	top: 8px;
	left: 100%;

	margin-top: 0;
	margin-left: 8px;
}

button.rmSearchForm__actionsAdd-KDamM {
	width: 36px;
	min-width: 0;
	min-width: initial;
	height: 36px;

	border-radius: 50%;

	color: var(--white);

	background-color: var(--brand-1-0);
}

button.rmSearchForm__actionsAdd-KDamM:not(:disabled):hover {
	background-color: var(--brand-1-2);
}

/* SearchFormDatepicker */
.rmSearchForm__select_month-1kG1D {
	padding-right: 4px;
}

.rmSearchForm__select_year-3nk_S {
	padding-right: 0;
}

.rmSearchForm__select_month-1kG1D,
.rmSearchForm__select_year-3nk_S {
	position: relative;

	color: var(--rm-brand-1-0);
	font-size: 16px;
	font-weight: 700;
}

.rmSearchForm__select_month-1kG1D:before,
.rmSearchForm__select_year-3nk_S:before {
	content: '';

	position: absolute;
	left: 0;
	bottom: 6px;

	width: 100%;
	height: 1px;

	background: var(--rm-brand-1-0);
}

.rmSearchForm__dayWeek-QcJpu {
	margin: 0 2px;

	color: #a0a4a8;
	font-weight: 700;

	font-size: 13px;
}

.rmSearchForm__dayOfWeekName-vvH-a span,
.rmSearchForm__dayOfWeekName_withPrices-16MAv span {
	color: currentColor;
	font-weight: 700;
}

.rmSearchForm__dayOfWeekName_withPrices-16MAv:nth-child(6n) span,
.rmSearchForm__dayOfWeekName_withPrices-16MAv:nth-child(7n) span {
	font-weight: 700;
}

.rmSearchForm__week_withPrice-1apHM {
	margin-top: 4px;
}

.rmSearchForm__day_inPeriod-2b0MC {
	--datepicker-day-period-background: var(--white);
}

.rmSearchForm__day_selected-3fL12 {
	background: var(--brand-1-0);
}

.rmSearchForm__day_withPrice-3nj5T {
	--dark-gray: var(--rm-brand-1-0);

	font-weight: 700;
	font-size: 15px;
	line-height: 1.3;
}

.rmSearchForm__day_withPrice-3nj5T div {
	color: var(--dark-gray);
}

.rmSearchForm__day_withPrice-3nj5T.rmSearchForm__day_inPeriod-2b0MC.rmSearchForm__day_startPeriod-udfnB {
	border-radius: 8px;

	color: var(--white);
}

.rmSearchForm__day_startHover_withPrice-9Cwes > div,
.rmSearchForm__day_endHover_withPrice-18gGC > div {
	color: var(--white);
}

.rmSearchForm__day_withPrice-3nj5T:hover > div,
.rmSearchForm__day_withPrice-3nj5T:hover > div > span {
	color: var(--white);
}

.rmSearchForm__day_selected-3fL12.rmSearchForm__day_withPrice-3nj5T.rmSearchForm__day_inPeriod-2b0MC > div {
	color: inherit;
}

div.rmSearchForm__day_today-3RgI6 {
	--dark-gray: #034ea2;

	position: relative;

	border-color: #034ea2;
	border-radius: 4px;

	overflow: hidden;
}

.rmSearchForm__day_today-3RgI6:after {
	content: '';
	position: absolute;
	top: -8px;
	right: -8px;
	width: 16px;
	height: 16px;
	background: #bb2649;

	transform: rotate(45deg);
}

.rmSearchForm__day_startHover_withPrice-9Cwes {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.rmSearchForm__day_endHover_withPrice-18gGC {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.rmSearchForm__day_selected-3fL12.rmSearchForm__day_withPrice-3nj5T {
	--common-orange: var(--rm-brand-1-0);

	border-radius: 6px;
}

div.rmSearchForm__day_startHover_withPrice-9Cwes,
div.rmSearchForm__day_endHover_withPrice-18gGC {
	--datepicker-day-period-background: var(--rm-brand-1-0);

	color: var(--white);

	border-radius: 6px;
}

.rmSearchForm__day_withPrice-3nj5T:not(.rmSearchForm__day_endHover_withPrice-18gGC):not(.rmSearchForm__day_startHover_withPrice-9Cwes):not(.rmSearchForm__day_selected-3fL12) {
	position: relative;

	border-radius: 6px;
}

.rmSearchForm__day_withPrice-3nj5T:not(.rmSearchForm__day_endHover_withPrice-18gGC):not(.rmSearchForm__day_startHover_withPrice-9Cwes):not(.rmSearchForm__day_selected-3fL12):hover {
	color: var(--white);

	background: var(--common-orange);
}

.rmSearchForm__day_selected-3fL12.rmSearchForm__day_withPrice-3nj5T:before,
div.rmSearchForm__day_startHover_withPrice-9Cwes:before,
.rmSearchForm__day_withPrice-3nj5T:not(.rmSearchForm__day_endHover_withPrice-18gGC):not(.rmSearchForm__day_startHover_withPrice-9Cwes):not(.rmSearchForm__day_selected-3fL12):hover:before,
div.rmSearchForm__day_endHover_withPrice-18gGC:before {
	display: block;

	content: '';
	position: absolute;
	top: -2px;
	left: -2px;
	right: -2px;
	bottom: -2px;
	width: 100%;
	height: 100%;

	background: transparent;
	border-radius: 8px;
	border: 2px solid var(--common-orange);

	pointer-events: none;
	box-sizing: content-box;
}

.rmSearchForm__day_withPrice-3nj5T:not(.rmSearchForm__day_endHover_withPrice-18gGC):not(.rmSearchForm__day_startHover_withprice-3efOs):not(.rmSearchForm__day_selected-3fL12):hover
	div,
.rmSearchForm__day_withPrice-3nj5T:not(.rmSearchForm__day_endHover_withPrice-18gGC):not(.rmSearchForm__day_startHover_withprice-3efOs):not(.rmSearchForm__day_selected-3fL12):hover
	.rmSearchForm__day_price-RLhcF {
	color: var(--white);
}

.rmSearchForm__popup-2YsI2 {
	border-radius: 30px;

	box-shadow: none;
}

.rmSearchForm__disclaimer-2ffid {
	position: absolute;
	top: 0;
	left: 0;

	margin: 25px 30px;

	color: var(--rm-brand-1-0);
	font-weight: 500;
	font-size: 18px;
	line-height: 1.3;
}

button.rmSearchForm__tab-30gdy {
	height: 56px;

	margin: 0;
	padding: 0 16px;

	color: var(--rm-brand-1-0);
	font-weight: 500;
	font-size: 20px;
	line-height: 56px;

	opacity: 1;
}

button.rmSearchForm__tab_selected-1pv93 {
	color: var(--rm-brand-1-0);
}

span.rmSearchForm__tab_indicator-16aha {
	top: auto;
	bottom: 0;

	background: #bb2649;
}

button.rmSearchForm__footer_done-30mNG {
	margin-left: 20px;
}

button.rmSearchForm__footer_clear-Pm08u,
button.rmSearchForm__footer_done-30mNG {
	min-width: 160px;
	height: 56px;

	padding: 0 26px;

	border-radius: 20px;

	font-weight: 500;
	font-size: 16px;

	line-height: 56px;
}

button.rmSearchForm__footer_clear-Pm08u,
button.rmSearchForm__footer_clear-Pm08u:hover {
	border: 1px solid var(--rm-brand-1-0);

	color: var(--rm-brand-1-0);

	background: transparent;
}

button.rmSearchForm__footer_done-30mNG,
button.rmSearchForm__footer_done-30mNG:hover {
	color: var(--white);

	background: var(--rm-brand-1-0);
}

/* Datepicker */
.rmSearchForm__day-3GMtN {
	--common-orange: var(--rm-brand-1-0);

	margin: 0 2px;
	padding-top: 13px;

	border-radius: 4px;

	background: #f2f2f5;
}

.rmSearchForm__day-3GMtN.rmSearchForm__day_inPeriod-2b0MC {
	color: var(--text-pale);
	font-weight: 500;

	border: 1px solid var(--common-orange);
	border-radius: 4px;
}

.rmSearchForm__day_weekend-3E32L.rmSearchForm__day-3GMtN.rmSearchForm__day_inPeriod-2b0MC {
	font-weight: 700;
	color: var(--brand-1-0);

	border-radius: 6px;
}

.rmSearchForm__day-3GMtN.rmSearchForm__day_inPeriod-2b0MC div,
.rmSearchForm__day_weekend-3E32L.rmSearchForm__day-3GMtN.rmSearchForm__day_inPeriod-2b0MC div {
	color: var(--common-orange);
}

.rmSearchForm__day_notAvailable-35_cZ {
	color: var(--text-pale);
	font-weight: 500;
}

.rmSearchForm__dayToday-1_Ojc {
	--common-blue: var(--rm-brand-1-0);

	border-color: var(--common-orange);
}

.rmSearchForm__control-3I5c2 {
	margin-top: 17px;
}

/* Passengers */
button.rmSearchForm__decrease-1SN4f,
button.rmSearchForm__increase-jhtXT {
	color: #0033a0;

	background-color: rgba(0, 51, 160, 0.1);
}

button.rmSearchForm__decrease-1SN4f:hover,
button.rmSearchForm__increase-jhtXT:hover {
	color: #0033a0;

	background-color: rgba(0, 51, 160, 0.25);
}

button.rmSearchForm__decrease-1SN4f:disabled,
button.rmSearchForm__increase-jhtXT:disabled {
	color: var(--line-dot-line);

	background-color: var(--bg-fill);
}

button.rmSearchForm__decrease-1SN4f svg,
button.rmSearchForm__increase-jhtXT svg {
	fill: currentColor;
}

.rmSearchForm__counter-35TSk {
	font-size: 15px;
	font-weight: 700;
	color: var(--rm-brand-1-0);
}

.rmSearchForm__counter_disabled-3CEix {
	color: var(--disabled);
}

.rmSearchForm__passengerType-R2mpf {
	color: var(--rm-brand-1-0);
	font-weight: 700;
}

.rmSearchForm__age-1F_Xg {
	color: rgba(5, 22, 77, 0.6);
	font-weight: 400;
}

.rmSearchForm__age-1F_Xg:hover {
	color: var(--text-mid);
}

.searchFormDatepicker__content-1w_Ek {
	display: flex;
	flex-direction: column;

	margin-bottom: 20px;
}

.searchFormDatepicker__content-1w_Ek > div:first-child:not(:last-child) {
	margin-top: 60px;
}

.rmSearchForm__footerBar-Ox85H {
	padding-bottom: 40px;

	border-top: none;
}

.rmSearchForm__tabs-2J2Fy {
	position: relative;
	display: flex;

	width: 65%;
	height: 56px;

	margin-left: 50px;
}

.rmSearchForm__tabs-2J2Fy:before {
	content: '';

	position: absolute;
	left: 0;
	bottom: 0;

	width: 100%;
	height: 1px;

	background: #000000;
}

div.rmSearchForm__footerButtons-39S1m {
	margin-right: 50px;
}

.rmSearchForm__day_price-RLhcF {
	--datepicker-day-min-price-color: var(--rm-brand-1-0);

	margin-top: 0;

	color: #9ba3b5;
	line-height: 1.4;
}

.rmSearchForm__day-3GMtN.rmSearchForm__day_inPeriod-2b0MC .rmSearchForm__day_price-RLhcF {
	color: var(--rm-brand-1-0);
}

.monthsWrapper__navNext-2k0rk {
	right: 18px;

	transform: none;
}

.monthsWrapper__navNext-2k0rk,
.monthsWrapper__navNext-2k0rk:hover {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAiIGN5PSIyMC4wMDEiIHI9IjE5LjY2NjciIGZpbGw9IndoaXRlIiBzdHJva2U9IiMwNTE2NEQiIHN0cm9rZS13aWR0aD0iMC42NjY2NjciLz4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIwNzFfNjUxNikiPgo8cGF0aCBkPSJNMTUuMTk0MyAyNi44NTg2TDE2LjY2OTMgMjguMzMzNkwyNS4wMDI3IDIwLjAwMDNMMTYuNjY5MyAxMS42NjY5TDE1LjE5NDMgMTMuMTQxOUwyMi4wNTI3IDIwLjAwMDNMMTUuMTk0MyAyNi44NTg2WiIgZmlsbD0iIzA1MTY0RCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzIwNzFfNjUxNiI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwLjAwMSAxMC4wMDExKSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=) no-repeat center / contain;
}

.monthsWrapper__navPrev-1P9Vx {
	left: 18px;
}

.monthsWrapper__navPrev-1P9Vx,
.monthsWrapper__navPrev-1P9Vx:hover {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjAiIGN5PSIyMC4wMDEiIHI9IjE5LjY2NjciIHRyYW5zZm9ybT0icm90YXRlKDE4MCAyMCAyMC4wMDEpIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjMDUxNjREIiBzdHJva2Utd2lkdGg9IjAuNjY2NjY3Ii8+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yMDcxXzY1MjMpIj4KPHBhdGggZD0iTTI0LjgwNTcgMTMuMTQzNEwyMy4zMzA3IDExLjY2ODRMMTQuOTk3MyAyMC4wMDE3TDIzLjMzMDcgMjguMzM1TDI0LjgwNTcgMjYuODZMMTcuOTQ3MyAyMC4wMDE3TDI0LjgwNTcgMTMuMTQzNFoiIGZpbGw9IiMwNTE2NEQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yMDcxXzY1MjMiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgLTEgMjkuOTk5IDMwLjAwMDkpIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==) no-repeat center / contain;
}

.monthsWrapper__navNext-2k0rk,
.monthsWrapper__navPrev-1P9Vx {
	width: 40px;
	height: 40px;

	box-shadow: none;

	z-index: 2;
}

.rmSearchForm__autocomplete_alternative-1KFjT > div:last-child > div {
	z-index: 2;
}

.rmCurrency__dropdown-3G6Gw {
	border-radius: 15px;
	box-shadow: var(--shdw-dropdown);
	margin-top: 4px;
}

.rmSearchForm__switchItem-1MKq9.rmSearchForm__toggleActive-2bwqk:after {
	background: var(--brand-1-2);
}

@media (max-width: 1180px) {
	.rmSearchForm__month-KU-7K {
		padding-right: 20px;
	}
}

@media (max-width: 1024px) and (min-width: 601px) {
	.rmSearchForm__row-3xSHD {
		--input-height: 64px;
	}

	.rmSearchForm__segments-2BIIt {
		border-right: 0;
	}

	.rmSearchForm__cell-aIETQ {
		padding-right: 0;
	}

	.rmSearchForm__cell-aIETQ:not(:last-of-type) {
		padding-right: 0;

		border-right: 0;
	}

	.rmSearchForm__cell-aIETQ:nth-child(1),
	.rmSearchForm__cell-aIETQ:nth-child(1) .rmSearchForm__input-1UnsN,
	.rmSearchForm__cell-aIETQ:nth-child(1) .rmSearchForm__locationValue-3cOEX {
		border-radius: 16px 0 0 0;
	}

	.rmSearchForm__cell-aIETQ:nth-child(2),
	.rmSearchForm__cell-aIETQ:nth-child(2) .rmSearchForm__input-1UnsN,
	.rmSearchForm__cell-aIETQ:nth-child(2) .rmSearchForm__locationValue-3cOEX {
		border-radius: 0 16px 0 0;
	}

	.rmSearchForm__cell_date-ZVBdm {
		height: var(--input-height);
		margin-top: 0;
		padding-right: 0;

		border-right: 1px solid var(--line-separator);
	}

	.rmSearchForm__controls-p2tJN {
		position: static;
		top: auto;
		right: auto;

		flex: 1 1 100%;
		max-width: 100%;
		padding-right: 0;
	}

	.rmSearchForm__startSearch-oqQAK button {
		border-radius: 0 0 4px 0;
	}

	.rmSearchForm__passengers-G7DAd {
		position: absolute;
		top: var(--input-height);
		right: 0;

		width: 50%;
		margin-left: 1px;
	}

	.rmCurrency-uQOVL {
		flex: 1 0 45%;
		max-width: 45%;
		min-width: 100px;
	}

	button.rmCurrency__selector-10b5y {
		border-radius: 0 0 0 16px;
	}

	.rmSearchForm__segments_multiCity-2tL9G {
		margin-bottom: 16px;
	}

	.rmSearchForm__controls_multiCity-1lD5N .rmSearchForm__passengers-G7DAd {
		position: relative;
		top: 0;

		margin-left: 0;
	}

	.rmSearchForm__controls_multiCity-1lD5N button.rmCurrency__selector-10b5y {
		border-radius: 0;
	}

	/*.rmSearchForm__segments_multiCity .rmSearchForm__cell:nth-child(1),*/
	/*.rmSearchForm__segments_multiCity .rmSearchForm__cell:nth-child(1) .rmSearchForm__input {*/
	/*	border-radius: 16px 0 0 0;*/
	/*}*/

	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__cell-aIETQ:nth-child(1),
	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__cell-aIETQ:nth-child(1) .rmSearchForm__input-1UnsN {
		border-radius: 16px 0 0 16px;
	}

	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__cell-aIETQ:nth-child(2),
	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__cell-aIETQ:nth-child(2) .rmSearchForm__input-1UnsN {
		border-radius: 0;
	}

	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__cell_date-ZVBdm {
		border-right: 0;
		border-left: 1px solid var(--line-separator);
	}

	.rmSearchForm__actions-1glVz {
		position: relative;
		top: 0;
		left: auto;

		display: flex;
		width: 100%;
		margin-left: 0;
		margin-top: 16px;

		border-radius: 16px;
	}

	button.rmSearchForm__actionsAdd-KDamM,
	button.rmSearchForm__actionsRemove-1hxdJ {
		flex: 1;
		width: auto;
		height: 52px;

		border-radius: 0;

		color: var(--text-pale);

		background-color: var(--bg-elements);
	}

	button.rmSearchForm__actionsAdd-KDamM {
		border-radius: 4px 0 0 4px;
	}

	button.rmSearchForm__actionsRemove-1hxdJ {
		display: block;
		margin-left: 0;

		border-left: 1px solid var(--line-separator);
		border-radius: 0 4px 4px 0;
	}

	button.rmSearchForm__actionsAdd-KDamM:not(:disabled):hover,
	button.rmSearchForm__actionsRemove-1hxdJ:not(:disabled):hover {
		background-color: var(--bg-elements);
	}

	button.rmSearchForm__actionsAdd-KDamM path,
	button.rmSearchForm__actionsRemove-1hxdJ path {
		fill: var(--text-pale);
	}

	.rmSearchForm__startSearchMultiCity-3dV4Y.rmSearchForm__startSearch-oqQAK {
		border-radius: 0 16px 16px 0;
	}

	.rmSearchForm__actions-1glVz > *:only-child {
		border-radius: 16px;
	}

	.rmSearchForm__row-3xSHD .rmSearchForm__cell-aIETQ:not(.rmSearchForm__segment_withMultiCity-28OFh):last-child {
		border-right: none;
	}

	.rmSearchForm__startSearch-oqQAK {
		justify-content: flex-end;

		padding: 0 16px;

		border-radius: 0 0 16px 0;
	}

	.rmSearchForm__cell-aIETQ:nth-child(2) div.rmSearchForm__valueWrapper-3wjRU {
		padding-right: 16px;
	}
}

@media (max-width: 1024px) {
	.rmSearchForm__dialogBar-1GOKA {
		z-index: 1;

		color: var(--text-light);

		box-shadow: var(--shdw--header);
		background-color: var(--white);
	}

	.rmSearchForm__dialog_activeDate-1J6w1 {
		color: var(--rm-brand-1-0);
	}

	.rmSearchForm__dialogClose-1GGSe {
		top: 0;
		bottom: 0;

		width: 32px;
		height: 32px;
		margin: auto 0;

		border-radius: 50%;

		background-color: var(--bg-fill);
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjgzMzMgNS4zNDE3NUwxNC42NTgzIDQuMTY2NzVMOS45OTk5NiA4LjgyNTA4TDUuMzQxNjMgNC4xNjY3NUw0LjE2NjYzIDUuMzQxNzVMOC44MjQ5NiAxMC4wMDAxTDQuMTY2NjMgMTQuNjU4NEw1LjM0MTYzIDE1LjgzMzRMOS45OTk5NiAxMS4xNzUxTDE0LjY1ODMgMTUuODMzNEwxNS44MzMzIDE0LjY1ODRMMTEuMTc1IDEwLjAwMDFMMTUuODMzMyA1LjM0MTc1WiIgZmlsbD0iIzM5NURBMyIvPgo8L3N2Zz4K);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 20px;
	}

	.rmSearchForm__tabs-2J2Fy {
		width: 100%;
		margin: 0;
	}

	button.rmSearchForm__tab-30gdy {
		font-size: 16px;
		font-weight: 500;

		background-color: var(--bg-fill);
	}

	.rmSearchForm__tab_wrapper-3a7zZ svg {
		display: none;
	}

	.rmSearchForm__disclaimer-2ffid {
		width: 100%;

		margin: 0;
		padding: 20px;

		font-size: 14px;
	}

	.searchFormDatepicker__content-1w_Ek {
		margin-bottom: 100px;
		z-index: 1;
	}

	.rmSearchForm__footerBar-Ox85H {
		align-items: flex-start;
		height: calc(100% - 56px);

		padding-bottom: 0;
	}

	.rmSearchForm__popup-2YsI2 {
		padding-top: 112px;
	}

	.rmSearchForm__footerBar-Ox85H,
	.rmSearchForm__popup-2YsI2 {
		/* hack for position: fixed */
		transform: translate3d(0, 0, 0);
	}

	.searchFormDatepicker__content-1w_Ek > div:first-child:not(:last-child) {
		margin-top: 48px;
	}

	button.rmSearchForm__footer_clear-Pm08u,
	button.rmSearchForm__footer_done-30mNG {
		min-width: 140px;
		height: 48px;

		padding: 0 24px;

		border-radius: 10px;

		font-weight: 500;
		font-size: 16px;

		line-height: 48px;
	}

	div.rmSearchForm__footerButtons-39S1m {
		height: 72px;

		margin-right: 0;
		padding: 12px;
	}

	.rmSearchForm__cell-aIETQ:first-child:before,
	.rmSearchForm__cell-aIETQ:first-child:after {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.rmSearchForm-1PeWh {
		background-color: var(--brand-1-0);
		padding: 20px;
	}

	.rmSearchForm__segments-2BIIt {
		border-right: 0;
	}

	.rmSearchForm__cell-aIETQ:not(:last-of-type) {
		margin-bottom: 0;

		border-right: 0;
	}

	.rmSearchForm__alternative-2Z32G div.rmSearchForm__cell-aIETQ:nth-child(2) {
		padding-left: 0;
	}

	.rmSearchForm__alternative-2Z32G div.rmSearchForm__cell-aIETQ:first-of-type {
		padding-right: 0;
	}

	.rmSearchForm__actions-1glVz {
		position: relative;
		top: 0;
		left: auto;

		margin-left: 0;
	}

	button.rmSearchForm__actionsAdd-KDamM,
	button.rmSearchForm__actionsRemove-1hxdJ {
		width: 50%;
		height: 56px;

		border-radius: 0;

		color: var(--text-pale);

		background-color: var(--white);
	}

	button.rmSearchForm__actionsRemove-1hxdJ {
		margin-left: 0;

		border-left: 1px solid var(--line-separator);
	}

	button.rmSearchForm__actionsAdd-KDamM:not(:disabled):hover,
	button.rmSearchForm__actionsRemove-1hxdJ:not(:disabled):hover {
		background-color: var(--white);
	}

	button.rmSearchForm__actionsAdd-KDamM path,
	button.rmSearchForm__actionsRemove-1hxdJ path {
		fill: var(--text-pale);
	}

	.rmSearchForm__input-1UnsN {
		background-color: var(--white);
	}

	.rmSearchForm__cell-aIETQ:first-child .rmSearchForm__input-1UnsN {
		border-radius: 16px 0 0 0;
	}

	.rmSearchForm__cell-aIETQ:nth-child(2) .rmSearchForm__input-1UnsN {
		border-radius: 0 16px 0 0;
	}

	.rmSearchForm__dates-3YbNq {
		background-color: var(--white);
	}

	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__input-1UnsN {
		border-radius: 0;
	}

	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__dates-3YbNq {
		border-radius: 0;
	}

	.rmSearchForm__segments_multiCity-2tL9G .rmSearchForm__alternative-2Z32G .rmSearchForm__cell_date-ZVBdm {
		border-top: 0;
	}

	button.rmSearchForm__selector-3ii3k {
		background-color: var(--white);
	}

	.rmSearchForm__passengers_isMultiCity-3oKk- button.rmSearchForm__selector-3ii3k {
		height: 52px;

		border-radius: 0;
	}

	.rmSearchForm__code-2neTK {
		color: var(--text-base);
	}

	.rmSearchForm__wrapper_alternative-26vzC .rmSearchForm__value-1uXDc {
		color: var(--text-mid);

		opacity: 1;
	}

	.rmSearchForm__alternative-2Z32G .rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__cell_location-X7G_v .rmSearchForm__input-1UnsN {
		border-radius: 0;
	}

	.rmSearchForm__alternative-2Z32G:first-child
		.rmSearchForm__segment_withMultiCity-28OFh
		.rmSearchForm__cell_location-X7G_v:first-child
		.rmSearchForm__input-1UnsN {
		border-top-left-radius: 16px;
	}

	.rmSearchForm__alternative-2Z32G:first-child
		.rmSearchForm__segment_withMultiCity-28OFh
		.rmSearchForm__cell_date-ZVBdm:last-child
		.rmSearchForm__dates-3YbNq {
		border-top-right-radius: 16px;
	}

	.rmSearchForm__switcher_alternative-2fQ0g.rmSearchForm__switcher-1G4-B {
		color: var(--white);

		transform: translate(52%, -50%);
		background: var(--brand-1-0);
	}

	.rmSearchForm__passengers-G7DAd {
		flex: 1 1 100%;
		max-width: 100%;
		margin-top: 0;

		border-right: 0;
	}

	.rmSearchForm__controls-p2tJN {
		display: flex;
		flex-wrap: wrap;
	}

	.rmSearchForm__startSearch-oqQAK {
		position: static;

		flex: 1 1 100%;
		max-width: 100%;
		margin-top: 0;
	}

	.rmSearchForm__startSearch-oqQAK button {
		border-radius: 0 0 4px 4px;
	}

	.rmSearchForm__controls_multiCity-1lD5N .rmSearchForm__startSearch-oqQAK {
		flex-basis: 100%;

		min-height: var(--input-height);
		justify-content: center;

		margin-left: 0;
	}

	.rmSearchForm__alternative-2Z32G .rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__input-1UnsN:only-child {
		background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAyNCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjUgOWE3IDcgMCAxIDEgMTQgMGMwIDUuMy03IDEzLTcgMTNzLTctNy44LTctMTNaTTEwIDlhMi41IDIuNSAwIDEgMCA1IDAgMi41IDIuNSAwIDAgMC01IDBaIiBmaWxsPSIjNzM4REJFIi8+PC9zdmc+Cg==) center no-repeat var(--white);
		background-size: 24px;
	}

	.rmSearchForm__alternative-2Z32G .rmSearchForm__segment_withMultiCity-28OFh .rmSearchForm__cell-aIETQ {
		flex-basis: 33.33%;
	}

	.rmSearchForm__dates_alternative-2g43f.rmSearchForm__dates_oneDate-3SFAW .rmSearchForm__dates_placeholder-23cqH {
		background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAyNCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOC41IDRoMWEyIDIgMCAwIDEgMiAydjE0YTIgMiAwIDAgMS0yIDJoLTE0YTIgMiAwIDAgMS0yLTJWNmMwLTEuMS45LTIgMi0yaDFWMmgydjJoOFYyaDJ2MlptLTEzIDE2aDE0VjloLTE0djExWiIgZmlsbD0iIzczOERCRSIvPjwvc3ZnPgo=) center no-repeat;
		background-size: 24px;
	}

	.rmCurrency-uQOVL {
		margin-top: 0;

		border-right: 0;
	}

	button.rmCurrency__selector-10b5y {
		background-color: var(--white);
	}

	.rmCurrency__dropdown-3G6Gw {
		margin-top: 0;
		padding: 0;

		border-radius: 0;

		box-shadow: none;
	}

	div.rmSearchForm__footerButtons-39S1m {
		height: 64px;
		padding: 12px;

		box-shadow: var(--shdw-footer);
		background-color: var(--white);
	}

	div.rmSearchForm__footer_done-30mNG {
		margin: 0;

		color: var(--white);
		font-weight: 700;

		background: var(--rm-brand-1-0);
	}

	div.rmSearchForm__footer_clear-Pm08u {
		margin-right: 8px;
		margin-left: 0;

		border: 1px solid var(--rm-brand-1-0);

		color: var(--rm-brand-1-0);

		opacity: 1;

		background: transparent;
	}

	div.rmSearchForm__footer_done-30mNG,
	div.rmSearchForm__footer_clear-Pm08u {
		flex: 1;
		padding: 10px;

		height: 44px;

		border-radius: 6px;

		font-weight: 500;
		font-size: 15px;
		line-height: 1.4;
		text-align: center;
	}

	.rmSearchForm__dates_alternative-2g43f .rmSearchForm__dow-3QMxZ {
		display: inline-block;

		color: var(--text-base);
	}

	.datepicker__alternativeDow-33lsr {
		display: none;
	}

	.searchFormDatepicker__content-1w_Ek {
		margin-bottom: 64px;
	}

	.searchFormDatepicker__content-1w_Ek > div:first-child:not(:last-child) {
		margin-top: 76px;
	}

	.searchFormDatepicker__content-1w_Ek > div:first-of-type {
		position: relative;

		flex: 1;
		padding-bottom: 80px;
	}

	.rmSearchForm__disclaimer-2ffid {
		margin: 0;
	}

	.rmSearchForm__startSearch-oqQAK {
		border-radius: 0 0 16px 16px;
	}

	.rmSearchForm__row-3xSHD {
		--input-height: 56px;
	}

	.rmSearchForm__week_withPrice-1apHM {
		margin-top: 4px;
	}

	.rmSearchForm__day-3GMtN {
		margin: 0 2px;
	}

	.rmSearchForm__passengers_isMultiCity-3oKk-.rmSearchForm__passengers_withCurrency-1RwQd button.rmSearchForm__selector-3ii3k {
		border-radius: 0;
	}
}
