

.rmWarning-273R9 {
	--warnings-background: var(--warning-bg);
	--warnings-icon-color: var(--text-pale);
	--warnings-text-color: var(--text-mid);
	border-radius: 0;
	border: 1px solid var(--warning-icon);

	box-shadow: var(--shdw-card);
}

.rmWarning__inner-33zBL {
	padding: 16px;
}

.rmWarning__text-3dKsZ {
	font-size: 16px;
	line-height: 1.3;
	font-weight: 500;
}

.rmWarning__iconText-1B0jW {
	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;
}

.rmWarning__icon-3Mjq9 {
	margin-right: 12px;
}
