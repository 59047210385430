

.meal__detailsHeader-2nP4a {
	background-color: var(--bg-fill);
}

.meal__title-NRk4c {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3;
}

.meal__text-36yXr {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.meal__price-37UzJ > a {
	color: var(--text-light);
}

.meal__priceMoney-3kfEj {
	color: var(--text-base);
	font-size: 14px;
	font-weight: 700;
	line-height: 1.3;
}
