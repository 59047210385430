

button.seat_free--Z2Vl {
	border-color: #39fa8c;

	background: #39fa8c;
}

button.seat_1-1MLTg {
	border-color: #7eb5e6;

	background: #7eb5e6;
}

button.seat_2-8yI1H {
	border-color: #5a9cdd;

	background: #5a9cdd;
}

button.seat_3-1n0gR {
	border-color: #3767a7;

	background: #3767a7;
}

button.seat_4-QkcSV {
	border-color: #2e5587;

	background: #2e5587;
}

button.seat_expensive-mvVI7 {
	border-color: #ed8d40;

	background: #ed8d40;
}

button.seat_checkin-3Hcbl {
	border-color: #fbcf66;

	background: #fbcf66;
}
