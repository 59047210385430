.footer-3pQsB {
	background: white;
}

.footer_login-2w-IG .footer__text-2HztY {
	font-size: 15px;
	font-weight: 400;
	color: var(--text-base);
}

.footer__inner-3rh-7 .button-1eLPl {
	border-radius: 4px;

	background: var(--brand-1-0);
}

.footer__inner-3rh-7 .button-1eLPl:hover {
	background: var(--brand-1--1);
}

.description__header-38VY5 {
	color: var(--text-base);
}

.description__text-3YgSG {
	padding-left: 33px;

	font-size: 15px;
}

@media screen and (max-width: 600px) {
	.description__header-38VY5 {
		font-size: 20px;
	}

	.footer_login-2w-IG div.footer__text-2HztY {
		color: var(--text-base);
	}
}
