

.airplane__airplaneName-2U59K {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.airplane__airplaneName-2U59K > a {
	display: flex;
	align-items: center;

	color: var(--text-light);
	text-decoration: none;
}

.airplane__airplaneName-2U59K > a:after {
	width: 16px;
	height: 16px;
	margin-left: 6px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDhDMCAzLjg2IDMuMzYgMC41IDcuNSAwLjVDMTEuNjQgMC41IDE1IDMuODYgMTUgOEMxNSAxMi4xNCAxMS42NCAxNS41IDcuNSAxNS41QzMuMzYgMTUuNSAwIDEyLjE0IDAgOFpNOC4yNSA3LjI1VjExLjc1SDYuNzVWNy4yNUg4LjI1Wk03LjQ5OTk5IDE0QzQuMTkyNDkgMTQgMS40OTk5OSAxMS4zMDc1IDEuNDk5OTkgOEMxLjQ5OTk5IDQuNjkyNSA0LjE5MjQ5IDIgNy40OTk5OSAyQzEwLjgwNzUgMiAxMy41IDQuNjkyNSAxMy41IDhDMTMuNSAxMS4zMDc1IDEwLjgwNzUgMTQgNy40OTk5OSAxNFpNOC4yNSA0LjI1VjUuNzVINi43NVY0LjI1SDguMjVaIiBmaWxsPSIjNzM4REJFIi8+Cjwvc3ZnPgo=) no-repeat 50% 50% / contain;
	content: '';
}

.airplane__details-2p7eT {
	border-top: 1px dashed #d1d1d1;
}

.airplaneInfo__sunInfo-21gwZ {
	color: var(--text-light);
	font-size: 14px;
	line-height: 1.3;
}

.airplaneInfo__sunInfo-21gwZ > svg {
	color: var(--text-pale);
}

.passenger__item-2AIIE {
	padding: 0 12px;

	border: 1px solid var(--white);

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
	background-color: var(--white);
}

.passenger__avatar-3WIvG {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 500;

	background-color: var(--bg-fill);
}

.passenger__passengerName-3wxiS {
	max-width: 135px;

	color: var(--text);

	font-size: 14px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.passenger__item-2AIIE.passenger__itemConfirmed-2t_eZ .passenger__passengerName-3wxiS {
	color: var(--text-base);
}

.passenger__payload-2S5D6 {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

.passenger__money-2pbLu {
	margin-left: 4px;

	color: var(--brand-1-0);
}

.passenger__icon-39yIB {
	margin-left: 14px;
}

.passenger__icon-39yIB > svg {
	color: var(--text-pale);
}

.passenger__select-GfUJY {
	display: none;
}

.passenger__select-GfUJY.passenger__selectSelected-27nMe {
	display: flex;
}

.passenger__item-2AIIE.passenger__itemActive-2Twif {
	border: 1px solid var(--brand-1-2);

	background-color: var(--white);
}

.passenger__item-2AIIE.passenger__itemRecommend-2wgfw.passenger__itemActive-2Twif {
	background-color: var(--white);
}

.passenger__itemRecommend-2wgfw .passenger__avatar-3WIvG {
	color: var(--text-light);

	background-color: var(--bg-fill);
}

.passenger__item-2AIIE.passenger__itemActive-2Twif .passenger__avatar-3WIvG {
	color: var(--white);

	background-color: var(--brand-1-0);
}

.passenger__item-2AIIE.passenger__itemRecommend-2wgfw.passenger__itemActive-2Twif .passenger__avatar-3WIvG {
	color: var(--white);
}

.passenger__item-2AIIE.passenger__itemActive-2Twif .passenger__passengerName-3wxiS {
	color: var(--text);
}

.passenger__item-2AIIE.passenger__itemActive-2Twif.passenger__itemConfirmed-2t_eZ .passenger__passengerName-3wxiS {
	color: var(--text-base);
}

.passenger__item-2AIIE.passenger__itemActive-2Twif .passenger__payload-2S5D6 {
	color: var(--text-base);
}

.passenger__item-2AIIE.passenger__itemActive-2Twif .passenger__money-2pbLu,
.passenger__item-2AIIE.passenger__itemRecommend-2wgfw .passenger__money-2pbLu {
	color: var(--brand-1-0);
}

.passenger__seatClear-2wByj svg {
	color: var(--brand-1-0) !important;
}

.passenger__item-2AIIE.passenger__itemActive-2Twif .passenger__icon-39yIB > svg,
.passenger__item-2AIIE.passenger__itemRecommend-2wgfw .passenger__icon-39yIB > svg {
	color: var(--text-pale);
}

.passenger__item-2AIIE.passenger__itemActive-2Twif .passenger__select-GfUJY {
	display: flex;
}

.passengers__recommend-18T8p {
	justify-content: flex-start;

	color: var(--brand-1-0);
	font-weight: 400;
	font-size: 15px;
	line-height: 1.3;
}

.passengers__recommendIcon-rnuRj {
	color: var(--brand-1-0);
}

button.controls__button-jGYVn {
	border-radius: 6px;

	background-color: var(--bg-accent);
}

button.controls__button-jGYVn:hover {
	background-color: var(--brand-3--2);
}

.disclaimer__container-17-kG {
	position: relative;

	padding: 12px 38px;

	border: 1px solid var(--brand-1-3);
	border-radius: 4px;

	background-color: var(--brand-1-5);
}

.disclaimer__container-17-kG:before {
	content: '';

	position: absolute;
	top: 12px;
	left: 12px;

	width: 16px;
	height: 16px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDhDMCAzLjg2IDMuMzYgMC41IDcuNSAwLjVDMTEuNjQgMC41IDE1IDMuODYgMTUgOEMxNSAxMi4xNCAxMS42NCAxNS41IDcuNSAxNS41QzMuMzYgMTUuNSAwIDEyLjE0IDAgOFpNOC4yNSA3LjI1VjExLjc1SDYuNzVWNy4yNUg4LjI1Wk03LjQ5OTk5IDE0QzQuMTkyNDkgMTQgMS40OTk5OSAxMS4zMDc1IDEuNDk5OTkgOEMxLjQ5OTk5IDQuNjkyNSA0LjE5MjQ5IDIgNy40OTk5OSAyQzEwLjgwNzUgMiAxMy41IDQuNjkyNSAxMy41IDhDMTMuNSAxMS4zMDc1IDEwLjgwNzUgMTQgNy40OTk5OSAxNFpNOC4yNSA0LjI1VjUuNzVINi43NVY0LjI1SDguMjVaIiBmaWxsPSIjNzM4REJFIi8+Cjwvc3ZnPgo=) no-repeat 50% 50% / contain;
}

.disclaimer__container-17-kG:after {
	content: '';

	position: absolute;
	top: 12px;
	right: 12px;

	width: 16px;
	height: 16px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMTYgMC4zMzMwMDhMNS44MjUxNiAxLjUwODAxTDEwLjQ3NTIgNi4xNjYzNEgwLjMzMzQ5NlY3LjgzMzAxSDEwLjQ3NTJMNS44MjUxNiAxMi40OTEzTDcuMDAwMTYgMTMuNjY2M0wxMy42NjY4IDYuOTk5NjdMNy4wMDAxNiAwLjMzMzAwOFoiIGZpbGw9IiMwMDMzQTAiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
}

.disclaimer__container-17-kG > span {
	display: none;
}

.disclaimer__container-17-kG > p {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.seatsInfo__classInfoPayload-2A12R {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.seatsInfo__title-1wgtG {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3;
}

.seatsInfo__description-1Vx0O {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.seatsInfo__seatInfo-1EAGh {
	margin: 0 12px;
	padding: 16px 0;

	border-top: 1px dashed var(--line-separator);
}

.seatsInfo__seatPreferred-3kNgC .seatsInfo__seatIcon-2uqD7:before {
	content: '';

	position: absolute;
	top: 3px;
	left: 50%;

	width: 12px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IiMxNjQxOTMiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
	transform: translateX(-50%);
}

.seatMap__feature-kHw28 {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.seatMap__priceWrapper-LXcX7,
.seatMap__price-2E2rE {
	color: var(--text-base);
	font-size: 14px;
	font-weight: 700;
	line-height: 1.3;
}

.seatMap__container-1iz85 {
	margin-top: 4px;
}

.seatMap__wings-rcTKH:before,
.seatMap__wings-rcTKH:after {
	background: #efeeee;
}

.seatMap__rowServiceHeader-ReyAY {
	border-bottom: 1px solid var(--seat-economy-base);
}

.seatMap__rowServiceHeaderWrapper-1lWAt {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 500;

	background-color: var(--seat-economy-base);
}

.seatMap__rowServiceHeaderFrom-1yHg6.row_serviceHeader_from_after-1b92l {
	order: 1;
}

button.seat__seat-zt3J2 {
	height: 38px;

	border: 3px solid var(--seat-economy-base);
}

button.seat__seat-zt3J2:hover {
	border: 3px solid var(--brand-1-0);
}

button.seat__seat-zt3J2 .seat__number-t1PvS {
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
}

button.seat__seat-zt3J2.seat__seatOccupied-12ODc .seat__number-t1PvS {
	color: var(--white);
}

button.seat__seat-zt3J2.seat__seatRecommend-2mq_- .seat__number-t1PvS,
button.seat__seat-zt3J2.seat__seatRecommend-2mq_-.seat__seatRecommendCurrent-36ENw .seat__number-t1PvS {
	color: var(--brand-1-0);
}

button.seat__seat-zt3J2:not(.seat__seatUnexistent-gEwN5):not(.seat__seatUnavailable-yiELs):not(.seat__seatOccupied-12ODc):hover .seat__number-t1PvS {
	color: var(--brand-1-0);
}

button.seat__seat-zt3J2.seat__seatUnavailable-yiELs {
	border: 3px solid var(--seat-busy);
}

button.seat__seat-zt3J2.seat__seatRecommend-2mq_-,
button.seat__seat-zt3J2.seat__seatRecommend-2mq_-.seat__seatRecommendCurrent-36ENw {
	border: 3px solid var(--brand-1-0);

	background-color: var(--white);
}

button.seat__seat-zt3J2.seat__seatOccupied-12ODc,
button.seat__seatPreferred-2MHMH.seat__seatOccupied-12ODc {
	border: 3px solid var(--brand-1-0);

	background-color: var(--brand-1-0);
}

button.seat__seat-zt3J2.seat__seatOccupied-12ODc:before,
button.seat__seatPreferred-2MHMH.seat__seatOccupied-12ODc:before {
	display: none;
}

button.seat__seatBusiness-3HKU7 {
	border: 3px solid var(--seat-economy-front);
}

button.seat__seatPreferred-2MHMH {
	position: relative;

	border: 3px solid var(--seat-extra);
}

button.seat__seatPreferred-2MHMH:before {
	content: '';

	position: absolute;
	top: 1px;
	left: 50%;

	width: 12px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IiMxNjQxOTMiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
	transform: translateX(-50%);
}

.airplane__seatsDetails-7CO0f {
	border: 1px solid var(--brand-1-2);
	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;

	background-color: var(--brand-1-5);
}

.airplane__segment-1l_wW {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.seatRecommendPopup__main-3gBjB {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.seatRecommendPopup__mainIcon-WPQ8q {
	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--text-base);
}

.seatRecommendPopup__priceWrapper-3OKSh {
	align-items: center;

	color: var(--text-base);
	font-size: 14px;
	line-height: 1.3;
}

.seatRecommendPopup__money-2bhOY {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

div.seatTooltip__tooltip-dL3Pf {
	background: var(--brand-1-1);
}

div.seatTooltip__tooltip-dL3Pf:after {
	border-top-color: var(--brand-1-1);
}

.component__right-1cYAm {
	width: 320px;
}

.seatMap__exitLabel-1RzJs {
	color: var(--text-light);
}

.seatMap__exitLabel-1RzJs:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjg0MTcgNi4xNzVMMTEuNjY2NyA1TDYuNjY2NzUgMTBMMTEuNjY2NyAxNUwxMi44NDE3IDEzLjgyNUw5LjAyNTA4IDEwTDEyLjg0MTcgNi4xNzVaIiBmaWxsPSIjNzM4REJFIi8+Cjwvc3ZnPgo=) no-repeat 50% 50% / contain;
}

button.seat_free-1JxVl {
	border-color: var(--seat-free);
}

button.passenger__selectMobile-1lFoB,
button.passenger__selectMobile-1lFoB:hover {
	background-color: var(--common-blue);
}

button.passenger__selectMobile_selected-2XE4i,
button.passenger__selectMobile_selected-2XE4i:hover {
	border-color: var(--common-blue);

	color: var(--common-blue);

	background-color: var(--white);
}
