.toolbar__container-143iY {
	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
}

.toolbar__price-2Oh63 {
	color: var(--text-mid);
}

.toolbar__miles-1eppO {
	color: var(--text-light);
}

.toolbar__milesHint-32P6A {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjUgOEMwLjUgMy44NiAzLjg2IDAuNSA4IDAuNUMxMi4xNCAwLjUgMTUuNSAzLjg2IDE1LjUgOEMxNS41IDEyLjE0IDEyLjE0IDE1LjUgOCAxNS41QzMuODYgMTUuNSAwLjUgMTIuMTQgMC41IDhaTTguNzUgNy4yNVYxMS43NUg3LjI1VjcuMjVIOC43NVpNNy45OTk5OSAxNEM0LjY5MjQ5IDE0IDEuOTk5OTkgMTEuMzA3NSAxLjk5OTk5IDhDMS45OTk5OSA0LjY5MjUgNC42OTI0OSAyIDcuOTk5OTkgMkMxMS4zMDc1IDIgMTQgNC42OTI1IDE0IDhDMTQgMTEuMzA3NSAxMS4zMDc1IDE0IDcuOTk5OTkgMTRaTTguNzUgNC4yNVY1Ljc1SDcuMjVWNC4yNUg4Ljc1WiIgZmlsbD0iIzczOERCRSIvPgo8L3N2Zz4K) no-repeat 50% 50%;
	background-size: contain;
}

button.toolbar__button-26LjS {
	border-radius: 6px;

	font-weight: 700;
	font-size: 16px;

	background-color: var(--bg-accent);
}

button.toolbar__button-26LjS:hover {
	background-color: var(--brand-3--2);
}

@media screen and (max-width: 600px) {
	.toolbar__inner-3-f41 {
		padding: 0 12px;
	}

	.toolbar__container-143iY {
		padding: 12px 0;
		min-height: auto;
	}

	.toolbar__actions-3zmhK {
		min-height: auto;
	}
}
