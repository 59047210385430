

.weekCalendar__dayWrapper-Tzt7k {
	padding: 0;

	border-right: 1px solid var(--line-separator);
}

.weekCalendar__dayWrapper-Tzt7k:last-child {
	border-right: none;
}

.day__day-Ipyhb {
	min-height: 93px;
}

.day__date-2N0R6 {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.3;
}

.day__dateInfo-1FXfb {
	background-color: var(--white);
}

.day__dateInfo-1FXfb > span {
	color: var(--text-mid);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.day__dayNotAvailable-kj692 .day__date-2N0R6,
.day__dayNotAvailable-kj692 .day__dateInfo-1FXfb > span {
	color: var(--text-pale);
}

.day__priceWrapper-30s7J {
	padding-bottom: 6px;
}

.day__dayNotAvailable-kj692 .day__priceWrapper-30s7J {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS4wNjY3IDguNTAwMDJDMTUuMDY2NyAxMi4xODE5IDEyLjA4MTkgMTUuMTY2NyA4LjQwMDA1IDE1LjE2NjdDNi45NDc5OCAxNS4xNjY3IDUuNjA0MzQgMTQuNzAyNCA0LjUwOTQ2IDEzLjkxNDNMMTMuODE0MyA0LjYwOTQzQzE0LjYwMjUgNS43MDQzMiAxNS4wNjY3IDcuMDQ3OTUgMTUuMDY2NyA4LjUwMDAyWk0zLjI4NzY3IDEyLjc3OTFMMTIuNjc5MSAzLjM4NzY1QzExLjUyMTIgMi40MTc0NyAxMC4wMjg4IDEuODMzMzUgOC40MDAwNSAxLjgzMzM1QzQuNzE4MTUgMS44MzMzNSAxLjczMzM4IDQuODE4MTIgMS43MzMzOCA4LjUwMDAyQzEuNzMzMzggMTAuMTI4OCAyLjMxNzQ5IDExLjYyMTIgMy4yODc2NyAxMi43NzkxWk0xNi43MzM0IDguNTAwMDJDMTYuNzMzNCAxMy4xMDI0IDEzLjAwMjQgMTYuODMzNCA4LjQwMDA1IDE2LjgzMzRDMy43OTc2NyAxNi44MzM0IDAuMDY2NzExNCAxMy4xMDI0IDAuMDY2NzExNCA4LjUwMDAyQzAuMDY2NzExNCAzLjg5NzY1IDMuNzk3NjcgMC4xNjY2ODcgOC40MDAwNSAwLjE2NjY4N0MxMy4wMDI0IDAuMTY2Njg3IDE2LjczMzQgMy44OTc2NSAxNi43MzM0IDguNTAwMDJaIiBmaWxsPSIjNzM4REJFIi8+Cjwvc3ZnPgo=) no-repeat 50% 50%;
}

.day__day-Ipyhb.day__daySelected-OUjsa {
	border-bottom: 4px solid var(--brand-1-0);
}

.day__day-Ipyhb.day__daySelected-OUjsa .day__date-2N0R6 {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.3;
}

.day__dateInfo-1FXfb:before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: -1;

	width: 50%;

	border-bottom: 1px dashed var(--line-separator);

	transform: translate(-50%, -50%);
	content: '';
}

.day__price-1jbbE,
.day__day-Ipyhb.day__daySelected-OUjsa .day__price-1jbbE {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.day__day-Ipyhb.day__dayBest-KoNO9 .day__price-1jbbE {
	color: var(--text-base);
}

.slider__arrow-2Ftq8:after {
	margin-top: 0;
}
