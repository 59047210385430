.modalContent__header-aSnQV {
	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
}

.modalContent__headerTitle-oqXRF {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.modalContent__close-2dzu7 {
	color: var(--brand-1-0);
}

.modalContent__closeIcon-31pK8 {
	background-color: var(--brand-1-0);
}

button.modalContent__fareGroupButton-1tw7A {
	border: 1px solid var(--line-separator);
	border-radius: 4px;

	color: var(--brand-1-0);
	font-weight: 700;
	font-size: 15px;
	line-height: 1.3;

	background-color: var(--white);
}

button.modalContent__fareGroupButton-1tw7A:hover {
	color: var(--brand-1-0);

	background-color: var(--white);
}

button.modalContent__fareGroupButton-1tw7A.modalContent__fareGroupButtonActive-32EeP {
	border: 1px solid var(--brand-1-0);

	color: var(--white);

	background-color: var(--brand-1-0);
}

.modalContent__compareFaresLink-2EMKt {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.modalContent__compareFaresLinkIcon-6DNkg {
	color: var(--text-pale);
}

.modalContent__paginationIndex-2_eoL {
	display: none;
}

.modalContent__paper-3Rzs4 .slick-prev {
	left: 46%;
}

.modalContent__paper-3Rzs4 .slick-next {
	right: 46%;
}

.location__time-3bK18,
.location__city-1RSzy {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.location__timeIata-by1UH {
	color: var(--text-light);
}

.location__date-uQQcH {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.location__dateIcon-1rthS {
	color: var(--text-pale);
}

.location__terminal-t5YHg {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.location__weatherTemp-1hzM5 {
	font-size: 31px;
	font-weight: 300;
	line-height: 1.3;
}

.flightInfo__transfer-DdNZr > span,
.modalContent__totalTime-EfoXf {
	color: var(--text-base);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

button.modalContent__scrollUp-3OnBu {
	border-radius: 6px;

	font-weight: 700;
	font-size: 16px;
	line-height: 1.3;

	background-color: var(--brand-1-0);
}

button.modalContent__scrollUp-3OnBu:hover {
	background-color: var(--brand-1--1);
}

.modalContent__slider-2bLQV .slick-track {
	display: flex;
}

.modalContent__slider-2bLQV .slick-slide {
	height: inherit;
}

.modalContent__slider-2bLQV .slick-slide > div {
	height: 100%;
}

.selectedFareGroup__rules-3l-bH {
	flex-direction: column;
	align-items: flex-start;
}

@media screen and (max-width: 600px) {
	.modalContent__headerArrow-3UHoA {
		display: none;
	}

	.modalContent__headerTitle-oqXRF {
		font-size: 16px;
	}

	.modalContent__closeIcon-31pK8 {
		color: var(--text-light);

		background-color: var(--bg-fill);
	}

	.modalContent__compareFaresLink-2EMKt {
		display: none;
	}

	button.modalContent__scrollUp-3OnBu {
		width: 100%;

		background-color: var(--brand-3-0);
	}

	button.modalContent__scrollUp-3OnBu:hover {
		background-color: var(--brand-3--1);
	}
}
