.number-YPyBc {
	width: 38px;
	height: 37px;

	font-weight: 700;
	font-size: 16px;

	background: var(--brand-1-0);
}

.passengerName-2kgC7 {
	color: var(--text-base);
	font-weight: 700;
}

@media screen and (max-width: 600px) {
	.passengerName-2kgC7 {
		font-size: 16px;
	}
}
