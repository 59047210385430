.container-3pkqg {
	grid-template-columns: 1fr 1fr 1fr [content-end] 1.2fr [grid-end];
}

.contacts-2jUgC {
	display: none;
}

.field_sex-2WOGY {
	margin-top: 4px;
}

@media (min-width: 1025px) {
	div.field_birthdate-2Ps9L {
		grid-column-start: 4;
		grid-row-start: 1;
		width: 56%;
	}

	.field_sex-2WOGY {
		z-index: 0;

		grid-column-start: 4;
		grid-row-start: 1;
		align-items: start;
	}
}

@media (max-width: 1024px) {
	.container-3pkqg {
		grid-template-columns: 1fr;
	}

	.container-3pkqg.identity-3-kdF {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr [row-content-end] 1fr;
	}

	.field_sex-2WOGY {
		align-items: start;
	}
}

@media screen and (max-width: 600px) {
	div.field_birthdate-2Ps9L {
		width: calc(100% - 121px);
	}
}
