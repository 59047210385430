.desktopFlight__container-1aFFO {
	border-radius: 0;
}

.desktopFlight__container-1aFFO.desktopFlight__selected-TCC8N {
	margin: 0;

	border: 1px solid var(--brand-1-5);
	border-radius: 0;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.segment__flightInfoContainer-1lqos {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin: 0;
	min-width: 137px;
}

img.segment__flightInfoIcon-JjqtD {
	margin-bottom: 8px;
	margin-right: 0;
}

.segment__flightInfoIcon-JjqtD {
	width: auto;
}

.segment__routeInfo-4ckxw {
	padding-left: 0;
}

.segment__flightInfoContainerAirline-1GUAA,
.segment__flightInfoDate-2ZUFo {
	display: inline;

	color: var(--text-light);
	font-size: 11px;
	font-weight: 400;
	line-height: 1.3;
}

.segment__flightInfoAirline-pEB-O {
	display: none;
}

.segment__aircraft-2GJPg {
	border-right: 1px solid var(--line-separator);

	color: var(--text-light);
	font-size: 13px;
	line-height: 1.3;
}

.point__time-LNV_Y {
	color: var(--text-base);
	font-size: 42px;
	font-weight: 700;
	line-height: 1;
}

.point__thin-39cig .point__time-LNV_Y {
	color: var(--text-pale);
}

.point__airport-3l_nB {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.point__dateContainer-kX6t2 {
	justify-content: center;
}

.routeArrow__container-2uJwO {
	position: relative;

	justify-content: center;
}

.routeArrow__route-2hP4z:before {
	border-top: 1px solid var(--line-separator);
}

.routeArrow__circle-2d2ZF {
	border: 1px solid var(--line-separator);
}

.routeArrow__route-2hP4z:before,
.routeArrow__circle-2d2ZF {
	top: 20px;
}

.routeArrow__timeInRoute-19oOv {
	top: 0;

	margin-bottom: 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 15px;
	line-height: 1.3;
}

.routeArrow__flightNumber-1lB00 {
	color: var(--brand-1-2);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.flightPrice__price-1UjQv {
	border-left: 1px dashed var(--line-separator);
}

.flightPrice__best-2BWpX {
	color: var(--brand-1-0);
}

.flightPrice__selectFromBest-3jjXw {
	margin-bottom: 8px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

button.flightPrice__button-2jk_F {
	height: 45px;
	padding: 8px 16px;

	border-radius: 4px;

	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;

	background-color: var(--brand-3-0);
}

button.flightPrice__button-2jk_F:hover {
	background-color: var(--brand-3-0);
}

.stopsInfo__stopLabel-vxjm6 {
	color: var(--error-icon);
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
}

.segment__segment-e7Ff2.segment__segmentAdditional-37GPE {
	border-top: 1px dashed var(--line-separator);
}

.segment__flightInfoNextDay-1mVgf .segment__flightInfoDate-2ZUFo {
	color: var(--text-light);
}

.segment__totalDuration-Q-_bW {
	color: var(--text-base);
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
}

.flightPrice__fareFamily-2syPO {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

.flightPrice__price-1UjQv.flightPrice__priceSelected-3BS9H .flightPrice__pricesMoney-29868 {
	color: var(--brand-1-0);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.flightPrice__clear-aIqz8 {
	color: var(--brand-1-1);

	background-color: var(--brand-1-5);
}

@media screen and (max-width: 600px) {
	.routeArrow__timeInRoute-19oOv {
		font-size: 14px;
		font-weight: 500;
	}

	.routeArrow__flightNumber-1lB00 {
		color: var(--brand-1-2);
		font-weight: 700;
	}

	.point__dateContainer-kX6t2 {
		align-items: center !important;
	}
}
