

div.inputBase-Mq8q8 {
	padding-right: 0;
}

div.inputBase-Mq8q8 input {
	padding-right: 30% !important;
}

.input__button-2P5BK {
	background: transparent;
}

.input__button-2P5BK,
.indicator-PuudN {
	position: absolute;
	top: 50%;
	right: 12px;
	z-index: 5;

	transform: translateY(-50%);
}

.tooltip-28YSx p {
	color: var(--text-mid);
}
