

.wrapper-3Eb2L {
	padding: 12px;

	background: var(--white);
}

button.button_back-3euZf {
	width: 40px;
	height: 40px;

	border-radius: 50%;

	color: transparent;

	background: var(--bg-fill);
	overflow: hidden;
}

button.button_back-3euZf:before {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkFycm93IC8gIEJhY2siPgo8cGF0aCBpZD0iaWNvbi9uYXZpZ2F0aW9uL2Fycm93X2JhY2tfMjRweCIgZD0iTTE2LjY2NjYgOS4xNjY4M0g2LjUyNDkyTDExLjE4MzMgNC41MDg1TDkuOTk5OTIgMy4zMzM1TDMuMzMzMjUgMTAuMDAwMkw5Ljk5OTkyIDE2LjY2NjhMMTEuMTc0OSAxNS40OTE4TDYuNTI0OTIgMTAuODMzNUgxNi42NjY2VjkuMTY2ODNaIiBmaWxsPSIjMzk1REEzIi8+CjwvZz4KPC9zdmc+Cg==) no-repeat center / contain;
	transform: translate(-50%, -50%);
}

button.button_continue-VymhT {
	padding: 10px 24px;

	border-radius: 4px;

	font-size: 15px;

	background: var(--brand-3--1);
}
