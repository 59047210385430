.rmOrderStub__content-F8M5y {
	--common-blue: var(--brand-1-0);
}

div.rmTraveller-1jcwW {
	margin-bottom: 12px;

	border-radius: 8px;

	box-shadow: var(--shdw-card);
}

div.rmTraveller-1jcwW:last-child {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.rmTraveller__title-1snCi {
	--cabinet-action: var(--brand-1-5);
	--dark-gray: var(--brand-1-0);
}

div.rmTraveller-1jcwW .rmTraveller__title-1snCi > span {
	flex-shrink: 0;
	justify-content: center;
	margin-left: -4px;
	margin-right: 12px;
	width: 36px;
	height: 36px;

	border-radius: 50%;

	background: var(--brand-1-0);
}

.rmTraveller__title-1snCi > span path {
	fill: var(--brand-1-5);
}

.rmTraveller__title-1snCi.rmTraveller__add-3p1WD {
	--common-blue: var(--brand-1-0);
}

.rmTraveller__title-1snCi > p > span:first-child {
	font-size: 16px;
	line-height: 1.3;
}

div.rmTraveller-1jcwW .rmTraveller__header-2TWYl {
	padding-right: 8px;
	min-height: 76px;
}

.rmTraveller-1jcwW .rmTraveller__header-2TWYl path {
	color: var(--text-pale);
}

.rmTraveller__content-2lOPA {
	border-top: 1px solid var(--line-separator);
}

.rmAccount__activeRoute-1j33x > h1,
.rmAccount__activeRoute-1j33x > div > h1 {
	margin: 0 0 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.rmAccount__title-VY0wG {
	margin: 0 0 20px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.rmAccount__fullscreen-1K9cJ > div {
	border-radius: 8px;

	box-shadow: var(--shdw-card);
}

.rmAccount__offers-tYaM7 {
	padding: 0;

	color: var(--text-base);
}

.rmAccount__offers-tYaM7 label {
	display: none;
}

.rmAccount__offers-tYaM7 .rmAccount__checkbox-3Uiw- {
	color: var(--text-pale);
}

.rmAccount__offers-tYaM7 .rmAccount__checkbox-3Uiw-:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.rmAccount__offers-tYaM7 .rmAccount__checkbox-3Uiw-.rmAccount__checked-3XW2z {
	color: var(--brand-1-0);
}

.rmAccount__footer-3faFH {
	color: var(--text-mid);
}

.rmAccount__socialNetwork-1yX3I {
	color: var(--text-base);
	font-size: 15px;
	line-height: 1.3;
	font-weight: 500;
}

.rmAccount__socialAdd-2RLeu {
	--cabinet-action: var(--brand-3-0);
}

.test-29CuS {
	background-color: red;
}

.rmAccount-20T51 .rmInput-2lKBh {
	padding-right: 0;
}

.rmAccount-20T51 .rmInput__real-2rjEp {
	color: var(--text-base);
}

.rmAccount-20T51 .rmInput__textField-hmZEt {
	color: var(--text-light);
}

.rmAccount-20T51 .rmInput__labelShrink-3-y35 {
	color: var(--gray50);
}

.rmAccount-20T51 .rmInput__hint-36Xhh {
	position: absolute;
	bottom: -20px;
	left: 0;
}

.rmAccount-20T51 .rmSwitch-2CpnH {
	height: 56px;
}

.rmAccount-20T51 .rmSwitch__element-3shmq {
	border-color: var(--line-separator);

	color: var(--line-dot-line);

	background: var(--bg-fill);
}

.rmAccount-20T51 .rmSwitch__element-3shmq path {
	fill: var(--line-dot-line);
}

.rmAccount-20T51 .rmSwitch__element-3shmq.rmSwitch__element_active-1murL {
	border-color: var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.rmAccount-20T51 .rmSwitch__element-3shmq.rmSwitch__element_active-1murL path {
	fill: var(--brand-1-0);
}

.rmAccount-20T51 .rmSwitch__element-3shmq.rmSwitch__element_error-262qS {
	border-color: var(--error-stroke-hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

.rmAccount-20T51 .rmSwitch__element-3shmq.rmSwitch__element_error-262qS path {
	fill: var(--error-icon);
}

.rmAccount-20T51 .rmSwitch__error-Gjt0F {
	position: absolute;
	bottom: -17px;
	left: 0;
	overflow: visible;
	overflow: initial;
}

.rmAccount-20T51 .rmButton-1sWhs {
	--cabinet-action: var(--brand-1-0);
	--cabinet-action--hover: var(--brand-1--1);
	border-radius: 4px;
}

.rmOrder__item-3ANG2 {
	min-height: 44px;
}

.phone__input-3kD4H {
	display: none;
}

@media (max-width: 1024px) {
	.rmAccount__activeRoute-1j33x {
		background-color: var(--bg-fill);
	}

	.rmAccount__activeRoute-1j33x > h1,
	.rmAccount__activeRoute-1j33x > div > h1 {
		font-size: 20px;
	}

	div.rmTraveller-1jcwW {
		margin: 16px 0;
	}

	.rmAccount-20T51 .rmButton-1sWhs {
		--cabinet-action: var(--brand-3--1);
		--cabinet-action--hover: var(--brand-3-0);
	}
}
