

.includedBaggage__root-1UPoX {
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.includedBaggage__root-1UPoX:after {
	content: '';

	width: 100%;
	height: 49px;
}

.includedBaggage__header-2M1ey {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}
