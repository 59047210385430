.compareFares__container-w5mV1 {
	padding: 30px 32px 32px;
}

span.compareFares__modalHeading-8oTba {
	color: var(--text-base) !important;
	font-size: 22px !important;
	font-weight: 700 !important;
	line-height: 1.3 !important;
}

.compareFares__groupTitle-2KiMX {
	padding: 20px 0 10px;

	border-bottom: 1px solid var(--line-separator);

	color: var(--brand-1-0);
	font-weight: 400;
	font-size: 22px;
	line-height: 1.3;
}

.compareFares__tableRow-1VD4S {
	border-bottom: 1px solid var(--line-separator);
}

.compareFares__tableRow-1VD4S:nth-of-type(even) {
	background: var(--bg-fill);
}

.compareFares__title-hOlJr,
.compareFares__cell-3zYsD {
	font-size: 13px;
	line-height: 1.3;
	color: var(--text-base);
}

.compareFares__availabilityIcon-1CHic {
	color: var(--text-base);
}

.compareFares__slider-1ejFL .slick-prev {
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.compareFares__slider-1ejFL .slick-next {
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.04);
}

.compareFares__slider-1ejFL .slick-next,
.compareFares__slider-1ejFL .slick-prev,
.compareFares__slider-1ejFL .slick-next:hover,
.compareFares__slider-1ejFL .slick-prev:hover {
	border: 1px solid var(--bg-fill);

	color: var(--text-light);

	background-color: var(--white);
}

.compareFares__slider_withoutImages-PRx8V .slick-prev svg *,
.compareFares__slider_withoutImages-PRx8V .slick-next svg * {
	fill: var(--text-light);
}

.compareFares__fareHeadingItem-1qVeo {
	color: var(--white);

	background-color: var(--brand-1-0);
}

.compareFares__fareHeadingItemIsKey-WfOE9 {
	background-color: var(--brand-3-0);
}

.compareFares__link-35z_f {
	color: var(--text-base);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.3;
}

.compareFares__linkIcon_return-373cR {
	top: 2px;
}

@media screen and (max-width: 600px) {
	.compareFares__content-2qRYF {
		padding: 0 0 20px;
	}

	.compareFares__groupTitle-2KiMX {
		padding: 20px 20px 10px;
	}

	.compareFares__title-hOlJr {
		padding-left: 12px;

		font-weight: 400;
	}

	.compareFares__slider-1ejFL .slick-arrow {
		top: 8px;

		transform: inherit;
	}

	.compareFares__slider-1ejFL .slick-arrow.slick-prev {
		left: 12px;
	}

	.compareFares__slider-1ejFL .slick-arrow.slick-next {
		right: 12px;

		transform: rotate(180deg);
	}

	.compareFares__link-35z_f {
		margin-left: 20px;
		margin-bottom: 45px;
	}
}
