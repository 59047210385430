.rmInsurance-1WV0Q {
	border-radius: 4px;
	border: 1px solid var(--line-separator);
}

.rmInsurance__header-2EqYe {
	border-bottom: 0;
	border-radius: 2px 2px 0 0;

	color: var(--white);
	font-size: 16px;
	line-height: 1.3;

	background-color: var(--additional-insurance);
}

.rmInsurance__money-3rynD {
	color: var(--white);
	font-size: 16px;
	line-height: 1.3;
}

.rmInsurance-1WV0Q.rmInsurance_selected-3LsQb {
	border-color: var(--brand-1-2);
}

.rmInsurance-1WV0Q.rmInsurance_selected-3LsQb .rmInsurance__header-2EqYe {
	background-color: var(--brand-1-0);
}

.rmInsurance__option-DzVZf {
	margin-bottom: 15px;
	padding-bottom: 15px;

	border-bottom: 1px dashed var(--line-separator);

	color: var(--text-mid);
	font-weight: 400;
	font-size: 13px;
	line-height: 1.3;
}

.rmInsurance__option-DzVZf:after {
	color: #cccccc;
}

button.rmInsurance__button-1O7he {
	border-color: var(--brand-1-0);

	color: var(--white);

	background-color: var(--brand-1-0);
}

button.rmInsurance__button-1O7he:hover {
	border-color: var(--brand-1--1);

	color: var(--white);

	background-color: var(--brand-1--1);
}

button.rmInsurance__button-1O7he.rmInsurance__button_selected-W45Lz {
	border-color: var(--bg-fill);

	color: var(--text-light);
	font-weight: 400;

	background-color: var(--bg-fill);
}

button.rmInsurance__button-1O7he.rmInsurance__button_selected-W45Lz:hover {
	border-color: var(--brand-1-5);

	color: var(--text-light);

	background-color: var(--brand-1-5);
}

button.rmInsurance__button-1O7he.rmInsurance__button_selected-W45Lz:after {
	display: none;
}

.rmInsuranceDialog-2lddc > span {
	z-index: 1;
}

div.rmInsuranceDialog__header-3l1At {
	padding-right: 78px;

	border-bottom: 0;
	border-radius: 6px 6px 0 0;

	color: var(--white);

	background-color: var(--brand-1-0);
}

.rmInsuranceDialog__header-3l1At div {
	color: var(--white);
}

.rmInsuranceDialog__wrapper-Mk8h5 {
	background-color: var(--white);
}

a.rmInsuranceDialog__rules-2Z1IZ {
	color: var(--white);
}

button.rmInsuranceDialog__button_done-2b4Jb,
button.rmInsuranceDialog__button_clear-1zfq- {
	border-radius: 4px;
}

@media screen and (max-width: 600px) {
	div.rmInsuranceDialog__mobileWrapper-2VMn- {
		background-color: var(--white);
	}

	.rmInsuranceDialog__mobileHeader-2q7Z4 {
		background: var(--brand-1-1);
	}

	a.rmInsuranceDialog__mobileRules-3lWfJ {
		color: var(--text-light);
	}

	button.rmInsurance__button-1O7he.rmInsurance__button_selected-W45Lz .rmInsurance__money-3rynD {
		color: var(--text-light);
		font-weight: 400;
	}

	.rmInsurance__header-2EqYe {
		background-color: var(--brand-1-2);
	}

	.rmInsurance-1WV0Q.rmInsurance_selected-3LsQb {
		border-color: var(--brand-3-0);
	}

	.rmInsurance-1WV0Q.rmInsurance_selected-3LsQb .rmInsurance__header-2EqYe {
		background-color: var(--brand-3-0);
	}
}
