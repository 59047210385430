div.number-WiVrC {
	line-height: 37px;

	background: var(--success-icon);
}

div.header-1Ll7e {
	margin-bottom: 4px;
}

@media screen and (max-width: 600px) {
	div.passenger-31n8V.passenger_filled-gX7sc {
		padding: 0;
	}

	div.header-1Ll7e {
		padding-left: 35px;
	}

	div.number-WiVrC {
		left: 0;

		width: 24px;
		height: 24px;

		line-height: 24px;

		transform: none;
	}
}
