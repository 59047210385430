button.submit-s0XZz {
	height: var(--input-height);

	border: 1px solid #bb2649;

	border-radius: 15px;

	color: #bb2649;
	font-weight: 500;
	font-size: 18px;

	background: transparent;
}

button.submit-s0XZz:hover {
	color: var(--white);

	background: #bb2649;
}

label.label-2-7K4 + div.input-1x8vf fieldset {
	display: none;
}

label.label-2-7K4,
label.label-2-7K4.label-2-7K4.label_shrink-2mFCJ:not(.label_error-BivI1) {
	color: rgba(5, 22, 77, 0.8);
}

div.input-1x8vf {
	border-radius: 15px;

	background: rgba(240, 240, 240, 0.6);
}

div.tabs-3pWXm,
div.booking-iBbl1 {
	background: transparent;
}

div.booking-iBbl1 {
	padding: 0;
}

.booking__inner-2XPVA {
	max-width: 100%;
}

div.tabs-3pWXm {
	z-index: 1;

	padding: 0;

	border: none;
	border-radius: 30px;

	opacity: 1;

	background: rgba(217, 217, 217, 0.6);

	transition: opacity 0.15s;
}

div.tabs-3pWXm button {
	flex: 1;
	max-width: 100%;

	height: 80px;

	margin: 0;
	padding: 0;

	opacity: 1;
}

.tabs-3pWXm .tabs__desc-2C3ET {
	display: none;
}

div.tabs__container-3nuPL {
	z-index: 1;

	max-width: 100%;
}

div.tabLabel-1WiKR {
	color: var(--rm-brand-1-0);

	font-size: 20px;
	font-weight: 500;
	line-height: 1.3;

	transition: color 0.2s;
}

button.tabSelected-ztFyQ div.tabLabel-1WiKR {
	color: var(--white);
}

span.indicator-2sPyJ {
	height: 100%;
}

div.tabs-3pWXm span.indicator-2sPyJ {
	z-index: -1;

	border-radius: 30px;

	background: var(--rm-brand-1-0);
}

.container-3E4lM .rmSearchForm-hU3v4 {
	padding: 0;

	background: transparent;
}

.container-3E4lM .rmSearchForm__row-3JfJH {
	max-width: 100%;
}

.container-3E4lM .searchForm__currency-37Q7R {
	min-width: 98px;

	margin-left: 6px;
}

.container-3E4lM .searchForm__passengers-1CG97,
.container-3E4lM .searchForm__currency-37Q7R {
	border: none;
}

.container-3E4lM .searchForm__controlsMultiCity-1nuve {
	margin-top: 80px;
	margin-left: 0;
}

.container-3E4lM button.searchForm__passengersSelector-RInnA {
	min-width: 140px;

	padding-left: 12px;
}

.container-3E4lM button.searchForm__passengersSelector-RInnA,
.container-3E4lM button.searchForm__currencySelector-3jyi8 {
	justify-content: space-between;
	min-width: 99px;
	grid-gap: 4px;
	gap: 4px;
	padding-left: 12px;
	padding-right: 4px;

	border: 1px solid #f0f0f0;
	border-radius: 15px;

	color: rgba(5, 22, 77, 0.8);
	font-weight: 500;
	font-size: 16px;

	background: rgba(240, 240, 240, 0.5);
}

.container-3E4lM button.searchForm__passengersSelector-RInnA.searchForm__passengersSelectorFocused-1hKZA,
.container-3E4lM button.searchForm__currencySelector-3jyi8:focus {
	border-color: var(--rm-brand-1-0);
}

.container-3E4lM .searchForm__currencyArrowIcon-ntxsg {
	position: relative;
	right: auto;
}

.container-3E4lM .searchForm__passengersIsMultiCity-3xi25 {
	flex-basis: 50%;
}

.container-3E4lM .searchForm__passengersIsMultiCity-3xi25 button.searchForm__passengersSelector-RInnA {
	border-radius: 15px;
}

.container-3E4lM .searchForm__startSearch-1UTNx {
	margin-left: 6px;
	padding: 0;

	background: transparent;
}

.container-3E4lM .searchForm__startSearch-1UTNx.rmSearchForm__startSearchMultiCity-KlnmK {
	flex-basis: auto;

	margin-left: 0;
}

.container-3E4lM .rmSearchForm__startSearchIconMode-R3KZX.searchForm__startSearch-1UTNx button,
.container-3E4lM .rmSearchForm__startSearchMultiCity-KlnmK.searchForm__startSearch-1UTNx button {
	min-width: 140px;
	height: 48px;

	border: 1px solid #bb2649;
	border-radius: 15px;

	color: #bb2649;
	font-weight: 500;
}

/* stylelint-disable */
.container-3E4lM .searchForm__startSearch-1UTNx button {
	min-width: 100px;

	padding: 0 20px;

	border: 1px solid #bb2649;
	border-radius: 15px;

	color: #bb2649;
	font-weight: 500;
	font-size: 16px;

	background: transparent;
}

.container-3E4lM .searchForm__startSearch-1UTNx button:hover {
	color: var(--white);

	background: #bb2649;
}
/* stylelint-enable */
.container-3E4lM .rmSearchForm__startSearchMultiCity-KlnmK.searchForm__startSearch-1UTNx button:before {
	display: none;
}

.container-3E4lM .searchForm__datesTo-1qIXE:before {
	width: 20px;
	height: 20px;
}

.container-3E4lM .searchForm__datesPlaceholder-1KhgW {
	color: rgba(5, 22, 77, 0.8);
	font-size: 21px;
	font-weight: 500;
	line-height: 1.3;
}

.container-3E4lM .rmSearchForm__dates-3p5IM {
	padding: 0;
}

.container-3E4lM .rmSearchForm__dates-3p5IM:before {
	display: none;
}

.container-3E4lM .searchForm__returnDelete-Cu24x {
	right: 4px;
}

@media (max-width: 1024px) and (min-width: 601px) {
	.container-3E4lM .searchForm__passengers-1CG97 {
		position: relative;
		top: unset;
		right: unset;
	}
}

@media (max-width: 1100px) {
	.container-3E4lM button.searchForm__passengersSelector-RInnA {
		min-width: 132px;
	}

	.container-3E4lM .searchForm__currency-37Q7R,
	.container-3E4lM button.searchForm__currencySelector-3jyi8 {
		min-width: 90px;
	}

	.container-3E4lM button.searchForm__passengersSelector-RInnA,
	.container-3E4lM button.searchForm__currencySelector-3jyi8,
	.container-3E4lM .searchForm__startSearch-1UTNx button,
	.container-3E4lM .searchForm__datesPlaceholder-1KhgW {
		font-size: 15px;
	}
}

@media (max-width: 1024px) {
	div.tabs-3pWXm {
		border-radius: 15px;
	}

	div.tabs-3pWXm button {
		height: 40px;
	}

	div.tabs-3pWXm span.indicator-2sPyJ {
		border-radius: 15px;
	}

	div.tabLabel-1WiKR {
		font-size: 16px;
	}

	.container-3E4lM .searchForm__startSearch-1UTNx {
		margin-left: 4px;
	}

	.container-3E4lM button.searchForm__passengersSelector-RInnA,
	.container-3E4lM button.searchForm__currencySelector-3jyi8 {
		padding-left: 4px;
	}

	.container-3E4lM .searchForm__currency-37Q7R {
		margin-left: 4px;
	}

	.container-3E4lM .searchForm__currency-37Q7R,
	.container-3E4lM button.searchForm__currencySelector-3jyi8 {
		min-width: 68px;
	}

	.container-3E4lM button.searchForm__passengersSelector-RInnA {
		min-width: 100px;

		padding-left: 6px;
	}
}

@media screen and (max-width: 600px) {
	div.tabs-3pWXm {
		border-radius: 5px;

		background: rgba(255, 255, 255, 0.6);

		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
	}

	div.tabs-3pWXm button {
		height: 36px;
		padding: 0 6px;
	}

	div.tabLabel-1WiKR {
		font-size: 13px;
	}

	div.tabs-3pWXm span.indicator-2sPyJ {
		border-radius: 5px;
	}

	.container-3E4lM .searchForm__valueWrapper-1V--J {
		flex-direction: row;
		justify-content: space-between;
	}

	.container-3E4lM button.searchForm__passengersSelector-RInnA {
		height: 50px;

		padding: 0 8px;

		border: 1px solid rgba(51, 51, 51, 0.3);
		border-radius: 5px;

		background: transparent;
	}

	.container-3E4lM button.searchForm__passengersSelector-RInnA:after {
		content: '';

		width: 16px;
		height: 16px;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMyAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuOTE3NDggMS41QzcuNzQ3MzUgMS41IDguNDI2MzMgMi4xNzUgOC40MjYzMyAzQzguNDI2MzMgMy44MjUgNy43NDczNSA0LjUgNi45MTc0OCA0LjVDNi4wODc2MSA0LjUgNS40MDg2MyAzLjgyNSA1LjQwODYzIDNDNS40MDg2MyAyLjE3NSA2LjA4NzYxIDEuNSA2LjkxNzQ4IDEuNVpNNi45MTc0OCA4LjI1QzguOTU0NDMgOC4yNSAxMS4yOTMxIDkuMjE3NSAxMS40NDQgOS43NVYxMC41SDIuMzkwOTNWOS43NTc1QzIuNTQxODEgOS4yMTc1IDQuODgwNTMgOC4yNSA2LjkxNzQ4IDguMjVaTTYuOTE3NDggMEM1LjI1MDIgMCAzLjg5OTc4IDEuMzQyNSAzLjg5OTc4IDNDMy44OTk3OCA0LjY1NzUgNS4yNTAyIDYgNi45MTc0OCA2QzguNTg0NzYgNiA5LjkzNTE4IDQuNjU3NSA5LjkzNTE4IDNDOS45MzUxOCAxLjM0MjUgOC41ODQ3NiAwIDYuOTE3NDggMFpNNi45MTc0OCA2Ljc1QzQuOTAzMTYgNi43NSAwLjg4MjA4IDcuNzU1IDAuODgyMDggOS43NVYxMkgxMi45NTI5VjkuNzVDMTIuOTUyOSA3Ljc1NSA4LjkzMTc5IDYuNzUgNi45MTc0OCA2Ljc1WiIgZmlsbD0iIzAwMzNBMCIvPgo8L3N2Zz4K) no-repeat center / contain;
	}

	.container-3E4lM button.searchForm__passengersSelector-RInnA .searchForm__passengersTitle-kELar {
		color: rgba(5, 22, 77, 0.6);
		font-size: 13px;
		font-weight: 400;
		text-align: start;
	}

	.container-3E4lM .searchForm__passengersIsMultiCity-3xi25 button.searchForm__passengersSelector-RInnA {
		border-radius: 5px;
	}

	.container-3E4lM .searchForm__currency-37Q7R {
		margin-left: 0;
		margin-top: 8px;
	}

	.container-3E4lM button.searchForm__currencySelector-3jyi8 {
		justify-content: space-between;

		height: 50px;

		padding: 0 8px;

		border: 1px solid rgba(51, 51, 51, 0.3);
		border-radius: 5px;

		color: rgba(5, 22, 77, 0.6);
		font-size: 13px;

		background: transparent;
	}

	.container-3E4lM .searchForm__startSearch-1UTNx {
		margin-left: 0;
		margin-top: 30px;
	}

	.container-3E4lM .searchForm__startSearch-1UTNx button {
		border-radius: 5px;
	}

	button.submit-s0XZz {
		border-radius: 5px;

		font-size: 16px;
	}

	div.input__wrapper-14Cm3 {
		margin-bottom: 16px;
	}

	div.input-1x8vf {
		border: 1px solid rgba(51, 51, 51, 0.3);
		border-radius: 5px;
	}
}
