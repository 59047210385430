

.modal__closeIcon-1xGyj {
	top: 28px;
	right: 32px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;

	border-radius: 50%;

	color: var(--text-pale);

	background-color: var(--bg-fill);
}

.modal__closeIcon-1xGyj > svg {
	width: 60%;
	height: 60%;
}

.modal__modalRounded-eQVKO .modal__closeIcon-1xGyj {
	top: 20px;
	right: 20px;
}

.modal__modalRounded-eQVKO .modal__closeIcon-1xGyj > svg {
	width: auto;
	height: auto;
}
