.meal__meal-34ANS {
	border-radius: 4px;

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.meal__price-1YPcS {
	padding: 6px 16px;

	border-radius: 4px;

	color: var(--white);

	background-color: var(--brand-1-0);
}

.meal__priceSelected-18P5e {
	background-color: var(--brand-3-0);
}

.meal__meal-34ANS:after {
	border-radius: 4px;
}

.meal__meal-34ANS:hover {
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
}

.meal__meal-34ANS:hover .meal__price-1YPcS {
	background-color: var(--brand-1--1);
}

.meal__meal-34ANS:hover .meal__priceSelected-18P5e {
	background-color: var(--brand-3--1);
}

.meal__meal-34ANS:hover:after {
	border: 1px solid var(--brand-3--2);
}

.meal__image-MIDC_ {
	border-radius: 4px 4px 0 0;
}

.meal__name-1lGPv {
	color: var(--text-mid);
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

.meal__popupClose-37xzD > div {
	border: none;

	color: var(--text-pale);

	background-color: var(--bg-elements);
}

.meal__popupClose-37xzD > div:hover {
	border: none;

	color: var(--text-pale);

	background-color: var(--bg-elements);
}

.details__content-_hs4f {
	background-color: var(--bg-fill);
}

.details__name-2T4fQ {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.details__row-2Eu9h {
	border-bottom: 1px dashed var(--line-dot-line);
}

.details__row-2Eu9h:last-child {
	border-bottom: none;
}

.details__trigger-3dPga {
	color: var(--text-mid);
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3;
}

.details__price-ZCrh3 {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.details__counter-3FqJ4 {
	border-bottom: 1px dashed var(--line-separator);
}

.details__passenger-vxLTg {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.3;
}

.details__passengerIndex-1NhAe {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 500;

	background-color: var(--bg-fill);
}

.details__footer-2R37x {
	margin-top: 24px;
}

.details__totalText-3Lq5D,
.details__total-1FpC0 {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.details__clear-3eUKL {
	margin-left: 12px;
	padding-left: 12px;

	border-left: 1px solid var(--line-separator);

	color: var(--brand-1-0);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;
}

.details__descriptionRow-2Vgxj {
	color: var(--text-mid);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.details__addForAll-3AysC {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

button.details__buttonEmpty-3x_vE {
	background-color: var(--brand-3--1);
}

button.details__buttonEmpty-3x_vE:hover {
	background-color: var(--brand-3--2);
}

.details__image-2PB2q {
	border-radius: 4px 4px 0 0;
}

.details__selector-3vTtR {
	border-radius: 0 0 4px 4px;
}

@media screen and (max-width: 600px) {
	.details__totalText-3Lq5D,
	.details__total-1FpC0 {
		font-size: 16px;
	}
}
