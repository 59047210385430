.container-g07Uf {
	--common-blue: var(--rm-brand-1-0);
}

.day__tooltip-32iBi {
	--common-blue: var(--rm-brand-1-0);
}

.graphHeader-FL9Kw {
	background: var(--text-mid);
}

.graphHeader-FL9Kw:after {
	border-color: var(--text-mid) transparent transparent transparent;
}

.progress-mBsNV {
	opacity: 0.8;
}

.day__day-3zDpJ.day_focused--4d_z:not(.day_notAvailable-1GcLW):after {
	top: -36px;
}

.day__day-3zDpJ.day_focused--4d_z:not(.day_notAvailable-1GcLW):after,
.day__day-3zDpJ.day_focused--4d_z:not(.day_notAvailable-1GcLW) .progress-mBsNV {
	background: var(--brand-1-4);
}

button.button-4e9Jv {
	background: var(--brand-1-0);
}

button.button-4e9Jv:hover {
	background: var(--brand-1--1);
}

@media (min-width: 1024px) {
	.day__outDate-3-Hu5.day__day-3zDpJ:after,
	.day__backDate-ZM9_a.day__day-3zDpJ:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNjY2MzQgMTYuMzMzM0g3LjMzMzAxTDExLjQ5OTcgOS42NjY2N0gxNi4wODNDMTYuNzc0NyA5LjY2NjY3IDE3LjMzMyA5LjEwODMzIDE3LjMzMyA4LjQxNjY3QzE3LjMzMyA3LjcyNSAxNi43NzQ3IDcuMTY2NjcgMTYuMDgzIDcuMTY2NjdIMTEuNDk5N0w3LjMzMzAxIDAuNUw1LjY2NjM0IDAuNUw3Ljc0OTY3IDcuMTY2NjdIMy4xNjYzNEwxLjkxNjM0IDUuNUgwLjY2NjM0TDEuNDk5NjcgOC40MTY2N0wwLjY2NjM0IDExLjMzMzNMMS45MTYzNCAxMS4zMzMzTDMuMTY2MzQgOS42NjY2N0g3Ljc0OTY3TDUuNjY2MzQgMTYuMzMzM1oiIGZpbGw9IiMwNTE2NGQiLz4KPC9zdmc+Cg==);
	}
}

@media screen and (max-width: 600px) {
	.graphHeader-FL9Kw:after {
		left: 62px;
	}
}
