.upgradeBaggageDialog__modalHeader-R_yjy {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.baggageDialogHeader__description-2Vfyi {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.baggageDialogHeader__priceLabel-1OGkt {
	color: var(--text-base);
	font-weight: 700;
}

.baggageDialogHeader__priceWrapper-3dKGY {
	color: var(--text-base);
	font-size: 13px;
	font-weight: 700;
	line-height: 1.3;
}

.baggageDialogHeader__categoryTitle-1OPfc {
	color: var(--brand-1-0);
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.baggageDialogHeader__label-1AhXI {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.baggageDialogHeader__categoryPrice-2Rz-b {
	color: var(--text-base);
}

.baggagePassenger__root-1amYh {
	border-top: 1px dashed var(--line-separator);
}

.baggagePassenger__number-1acgx {
	color: var(--text-light);

	background: var(--bg-fill);
}

.baggagePassenger__name-2O9M2 {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.3;
}

span.baggagePassenger__checkboxRoot-1xnGx.baggagePassenger__checkboxRootChecked-1wC-1 {
	color: var(--brand-1-0);
}

.baggageFooter__priceWrapper-1ZaqQ {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.baggageFooter__priceBlockDelimiter-b0MSm {
	background-color: var(--line-separator);
}

.baggageFooter__clear-P5eUF {
	color: var(--brand-1-0);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
}

button.baggageFooter__cancelBtn-2kDOe {
	font-weight: 400;
	color: var(--text-light);

	background: var(--bg-fill);
}

button.baggageFooter__confirmBtn-2yNe1 {
	font-weight: 700;
	color: var(--white);

	background: var(--brand-3-0);
}

button.baggageFooter__confirmBtn-2yNe1:hover {
	background: var(--brand-3--1);
}

.baggageFooter__root-3rXOy button.baggageFooter__cancelBtn-2kDOe,
.baggageFooter__root-3rXOy button.baggageFooter__confirmBtn-2yNe1 {
	width: auto;
	height: 40px;
	padding: 10px 24px;

	font-size: 15px;
}

.headerEquipmentItem__wrapper-vkBIi {
	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

.headerEquipmentItem__wrapper-vkBIi.headerEquipmentItem__active-woxGb {
	background: var(--brand-1-0);
}

.baggageSegment__root-1sXhf {
	background-color: var(--brand-1-5);
}

.baggageSegment__name-jFq5W {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3;
}

.baggageSegment__price-1ArIK {
	color: var(--text-base);
	font-size: 20px;
	font-weight: 700;
	line-height: 1.3;
}

.mobileBaggageSelector__price-2qwJL {
	color: var(--text-base);
	font-size: 31px;
	font-weight: 700;
	line-height: 1.3;
}

.mobileBaggageSelector__footer-2tEuO {
	border-bottom: 1px solid var(--line-separator);

	color: var(--brand-1-0);
	font-weight: 500;
	font-size: 14px;
	line-height: 1.3;
}

.baggagePassenger__counterWrapper-1JXmn:only-child {
	justify-content: flex-end;
}

@media screen and (max-width: 600px) {
	.baggageDialogHeader__categoryPrice-2Rz-b {
		font-size: 31px;
		font-weight: 700;
		line-height: 1.3;
	}

	.baggageDialogHeader__categoryWrapper-3CadY {
		border-bottom: 1px solid var(--line-separator);
	}

	.baggagePassenger__root-1amYh:last-child {
		border-top: 1px dashed var(--line-separator);
	}
}
