

.element-3yjYy {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 52px;

	height: 48px;
	padding: 0;

	border: 1px solid var(--line-separator);

	color: var(--line-dot-line);

	background: var(--bg-fill);
}

.element-3yjYy:first-of-type {
	border-radius: 40px 0 0 40px;
}

.element-3yjYy:last-of-type {
	border-radius: 0 40px 40px 0;
}

.element-3yjYy.element_active-3Cu4c {
	border-color: var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.element-3yjYy.element_error-395fC {
	border-color: var(--error-stroke--hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

.element-3yjYy input {
	pointer-events: none;
}

.element-3yjYy svg,
.element-3yjYy:last-of-type svg {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
}

.error-1B2rE {
	color: var(--error-text);
	font-size: 12px;
	font-weight: 400;
	line-height: 1.3;
	text-align: inherit;
}

.form-1S78w .error-1B2rE {
	max-width: 110px;

	transform: translateY(100%);
}
