

button.optionEdit-2s3vB {
	border: none;
	border-radius: 4px;

	color: var(--text-mid);
	font: var(--normal-desktop);

	background: var(--brand-1-5);
}

button.optionEdit-2s3vB:hover {
	border-color: var(--brand-2-4);

	color: var(--text-mid);

	background: var(--brand-1-4);
}

button.optionEdit-2s3vB.disabled-15XvJ {
	color: var(--brand-1-3);

	cursor: not-allowed;

	background: var(--brand-1-4);
}
