

.baggageTotalPriceBlock__price-1oviy {
	color: var(--text-base);
	font-size: 15px;
	font-weight: 700;
	line-height: 1.3;
}

button.baggageTotalPriceBlock__control-2i6bg {
	position: relative;

	min-width: 33px;
	max-width: 33px;
	height: 33px;
	padding: 0;

	border: none;
	border-radius: 50%;

	color: transparent;

	background: var(--brand-1-0);
	overflow: hidden;
}

button.baggageTotalPriceBlock__control-2i6bg.baggageTotalPriceBlock__controlEdit-2rm3a {
	width: auto;
	max-width: inherit;
	padding: 6px 16px;

	border-radius: 4px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 14px;
	text-transform: capitalize;

	background: var(--brand-1-5);
}

button.baggageTotalPriceBlock__control-2i6bg.baggageTotalPriceBlock__controlEdit-2rm3a:hover {
	color: var(--text-mid);

	background: var(--brand-1-4);
}

button.baggageTotalPriceBlock__control-2i6bg.baggageTotalPriceBlock__controlEdit-2rm3a:before,
button.baggageTotalPriceBlock__control-2i6bg.baggageTotalPriceBlock__controlEdit-2rm3a:after {
	display: none;
}

button.baggageTotalPriceBlock__control-2i6bg:hover {
	border: none;

	color: transparent;

	background: var(--brand-1--1);
}

button.baggageTotalPriceBlock__control-2i6bg:before,
button.baggageTotalPriceBlock__control-2i6bg:after {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	width: 45%;
	height: 2px;

	transform: translate(-50%, -50%);
	background-color: var(--white);

	pointer-events: none;
	content: '';
}

button.baggageTotalPriceBlock__control-2i6bg:after {
	transform: translate(-50%, -50%) rotate(90deg);
}
