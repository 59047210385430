

.stepbar__container-PK7-G {
	border-bottom: 1px solid var(--brand-1-1);

	background-color: var(--white);
}

.step__step-32TF2 {
	position: relative;

	flex: 0;

	margin-right: 31px;

	border: none;

	color: var(--brand-1-2);
	font-weight: 700;
	font-size: 13px;
	line-height: 1.3;
}

.step__step-32TF2.step__step_passed-38kZJ {
	color: var(--brand-1-0);
}

.step__step-32TF2.step__stepActive-l22VV {
	color: var(--brand-1-0);
}

.step__additional-2kfov {
	color: var(--brand-1-0);
}

.step__step-32TF2.step__stepActive-l22VV:before {
	content: '';

	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;

	height: 2px;

	background-color: var(--brand-1-0);
}

.step__arrow-BtlF- {
	display: none;
}

.step__step-32TF2.step__stepDisabled-W9yle {
	color: var(--text-pale);
}

.id-5rWAa {
	display: none;
}
