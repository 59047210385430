

.switch__wrapper-2gcM7.switch__active-1Uxjh:before {
	background: var(--white);
}

.switch__wrapper-2gcM7.switch__active-1Uxjh:after {
	background: var(--brand-1-0);
}

.switch__wrapper-2gcM7:after {
	background-color: var(--line-separator);
	opacity: 1;
}
