.rmDocument__title-1j8wb {
	margin: 24px 0 0 32px;
}

.rmDocument__title-1j8wb h1 {
	color: var(--text-base);
	font-weight: 500;
	font-size: 24px;
	line-height: 1.3;
}

.rmDocument__title-1j8wb span {
	display: none;
}

.rmDocument__form-2lQW6 {
	margin: 36px 40px 40px;
	--common-blue: var(--brand-1-1);
}

.rmDocument__footer-TLJ_F button {
	--cabinet-action: var(--brand-3-0);
	--cabinet-action--hover: var(--brand-3--1);
	min-width: 160px;
	height: 50px;

	border-radius: 6px;

	font-weight: 700;
	font-size: 18px;
	line-height: 50px;
}
@media screen and (max-width: 600px) {
	.rmDocument__title-1j8wb > h1 > span {
		display: none;
	}
}
