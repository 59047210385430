.list__item-31kAj {
	padding: 12px 16px;

	border: 1px solid var(--line-separator);
	border-radius: 4px;

	color: var(--text-mid);
	font-weight: 700;
	font-size: 15px;
	line-height: 1.3;

	background-color: var(--white);
}

.list__item-31kAj:not(:last-of-type) {
	margin-right: 12px;
}

.list__item-31kAj.list__itemSelected-2c_TV {
	border: 1px solid var(--brand-1-0);

	color: var(--white);

	background-color: var(--brand-1-0);
}

.list__itemIcon-LyNIB {
	display: none;
}

@media screen and (max-width: 600px) {
	.list__wrapper--JKYe {
		flex-flow: nowrap column;
	}

	.list__item-31kAj {
		width: 100%;
		margin: 0;

		border: none;

		font-weight: 500;
		font-size: 15px;
	}

	.list__item-31kAj:not(:last-of-type) {
		margin-right: 0;
	}

	.list__item-31kAj.list__itemSelected-2c_TV {
		border: none;

		color: var(--text-base);
		font-weight: 700;

		background-color: var(--brand-1-5);
	}

	.categories__mobileHeader-3OVbZ {
		margin-bottom: 20px;
	}
}
