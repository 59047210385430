

.fareLock__root-3DyE1 {
	border-radius: 4px;
	border: 1px solid var(--brand-1-4);

	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
	overflow: hidden;
}

.switchBlock__root-2qH73 {
	border-left: 1px dashed var(--line-separator);

	color: var(--brand-1-0);

	background-color: var(--white);
}

.descriptionBlock__title-6SC9z {
	color: var(--text-mid);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.descriptionBlock__description-19ZCK {
	color: var(--text-light);
	font-size: 15px;
	font-weight: 400;
	line-height: 1.3;
}
