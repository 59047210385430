/* login */
.rmInital-2GK-h {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
}

.rmInital__form-2-cnQ {
	flex: 1 1 50%;
	order: 2;
	max-width: 50%;
	padding: 28px 32px;

	border-radius: 20px;

	box-shadow: none;
}

.rmInital__form-2-cnQ:hover {
	box-shadow: none;
}

.rmInital__hint-IUsPt {
	flex: 1 1 50%;
	order: 1;
	max-width: 50%;
	margin-top: 0;
	padding: 28px 32px;

	border-radius: 20px;

	box-shadow: none;
}

.rmInital__hint-IUsPt:hover {
	box-shadow: none;
}

.rmInital__hintTitle-11XzU {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.rmInital__hintInfo-2uJGj {
	margin-top: 20px;
}

.rmInital__hintIcon-18fyM path {
	fill: var(--text-pale);
}

.rmInital__hintText-1LX7_ {
	color: var(--text-mid);
	font-size: 15px;
	line-height: 1.3;
}

.rmInital__hintImage-3DlD3 {
	height: 136px;
}

.rmResendTicketsForm__wrapper-DTEj8,
.rmCancelModal__content-3Meyx {
	padding-top: 50px;
}

.rmSegment__footerItem-sYmIO span {
	display: block;
}

.rmCancelModal-8FYDv > div > span,
.rmResendTicketsForm__close-2whvd {
	top: 15px;
	right: 15px;
}

.rmSegment__footerItem-sYmIO span:first-child {
	font-size: 14px;
	line-height: 1.3;
	color: var(--text-light);
	text-transform: uppercase;
}

.rmSegment__footerItem-sYmIO span:last-child {
	font-size: 16px;
	line-height: 1.3;
	font-weight: 700;
}

.rmRetrieveBooking-3fwLp .rmInput__textField-2FoMt {
	color: var(--text-light);
}

.rmRetrieveBooking__title-1I_xy {
	color: var(--text-base);
	font-size: 22px;
	font-weight: 700;
	line-height: 1.3;
}

.rmRetrieveBooking__icon-1T3sc {
	display: none;
}

.rmRetrieveBooking__form-1pTCa {
	margin-top: 20px;
}

.rmRetrieveBooking__row-1m4Xh {
	margin-top: 32px;
}

button.rmRetrieveBooking__button-1uT_c {
	--cabinet-action: var(--brand-3-0);
	--cabinet-action--hover: var(--brand-3--1);
	height: 50px;

	border-radius: 6px;

	line-height: 50px;
}

/* modal */
.rmClarifyDocument__header-1aCuX {
	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.rmClarifyDocument__message-ctmLs {
	color: var(--text-mid);
}

.rmClarifyDocument__form-qcvb7 {
	flex-wrap: wrap;
	grid-row-gap: 24px;
	row-gap: 24px;
}

.rmClarifyDocument__form-qcvb7 > div:not(.rmClarifyDocument__submit-1NALc) {
	flex: 1 1 100%;
	max-width: 100%;
}

.rmClarifyDocument__form-qcvb7 .rmInput__textField-2FoMt {
	color: var(--text-light);
}

div.rmClarifyDocument__submit-1NALc {
	margin-left: auto;
}

button.rmClarifyDocument__submit-1NALc {
	--cabinet-action: var(--brand-3--1);
	--cabinet-action--hover: var(--brand-3-0);
	margin-left: auto;

	border-radius: 4px;

	font-weight: 700;
}

@media (max-width: 1024px) {
	.rmInital-2GK-h {
		flex-wrap: wrap;
		padding-left: 16px;
		padding-right: 16px;
	}

	.rmInital__form-2-cnQ {
		order: 1;
		flex: 1 1 100%;
		max-width: 100%;
	}

	.rmInital__hint-IUsPt {
		order: 2;
		flex: 1 1 100%;
		max-width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.rmInital__form-2-cnQ {
		padding: 0;

		border-radius: 0;

		background-color: transparent;
	}

	.rmInital__hint-IUsPt {
		padding: 20px;

		border-radius: 0;
	}

	.rmInital__hintTitle-11XzU {
		font-size: 20px;
	}

	.rmInital__hintInfo-2uJGj {
		margin-top: 16px;
	}

	.rmRetrieveBooking-3fwLp .rmInput-2JtVo.rmInput_filled-2OE_U {
		--input-background: var(--white);
	}

	.rmRetrieveBooking-3fwLp .rmInput__real-BUIV1 {
		color: var(--text-base);
	}

	.rmRetrieveBooking-3fwLp .rmInput-2JtVo.rmInput_filled-2OE_U input.rmInput__real-BUIV1 {
		border-width: 0 0 1px 0;
		border-radius: 0;
	}

	.rmRetrieveBooking-3fwLp .rmInput-2JtVo.rmInput_focused-2VSpP input.rmInput__real-BUIV1 {
		background: var(--brand-1-5);
	}

	.rmRetrieveBooking-3fwLp .rmInput__labelShrink-3qcV6 {
		color: var(--gray50);
	}

	.rmRetrieveBooking__title-1I_xy {
		padding: 16px 0;

		font-size: 20px;
	}

	.rmRetrieveBooking__form-1pTCa {
		margin-top: 0;
		padding: 20px;

		border-radius: 4px;

		background-color: var(--white);
	}

	.rmRetrieveBooking__row-1m4Xh {
		margin-top: 24px;
	}

	button.rmRetrieveBooking__button-1uT_c {
		width: 100%;
		height: 40px;

		line-height: 40px;
	}
}

/* inner page */
.rmCheckin-kmjDn .rmInput__textField-2FoMt {
	color: var(--text-light);
}

.rmCheckin__title-2RZDI {
	color: var(--text-base);
}

.rmSelectSegmentsModal__header-2Mt_l {
	background-color: var(--brand-1-0);
}

button.rmSelectSegmentsModal__button-22DPW {
	font-weight: 700;

	background: var(--brand-3--1);
}

button.rmSelectSegmentsModal__button-22DPW:hover {
	background: var(--brand-3-0);
}

.rmSelectableSegmentDesktop__dateWrp-WUA4W {
	color: var(--text-base);
}

.rmSelectableSegmentDesktop__time-24WQR {
	color: var(--text-light);
}

.rmSelectableSegmentDesktop__flight-1MdX_ {
	color: var(--text-light);
}

.rmSelectableSegmentDesktop__route-18wee {
	color: var(--text-base);
}

.rmSelectableSegmentDesktop__iata-1ir1P {
	color: var(--text-pale);
}

.rmSelectableSegmentDesktop__airplane-1BRB9 {
	color: var(--text-pale);
}

.rmSelectableSegmentMobile-2VvT- {
	color: var(--text-base);
}

.rmSelectableSegmentMobile__time-1phAC {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
}

.rmSelectableSegmentMobile__iatas-2mQJH {
	font-size: 44px;
	line-height: 44px;
}

.rmSelectableSegmentMobile__planeIcon-F6Ono:before,
.rmSelectableSegmentMobile__planeIcon-F6Ono:after {
	display: none;
}

.rmSelectableSegmentMobile__planeIcon-F6Ono svg {
	width: 44px;
	height: 44px;

	color: var(--text-pale);
}

.rmSelectableSegmentMobile__cities-1GwBr {
	top: 0;

	font-size: 13px;
	line-height: 1.3;
	text-transform: uppercase;
	color: var(--text-light);
}

.rmSelectableSegmentMobile__bottom-2ldYa {
	margin-top: 0;

	color: var(--text-base);
}

.rmSegment-32YGQ {
	color: var(--text-base);

	box-shadow: var(--shdw-card);
	background: var(--white);
}

.rmSegment__header-epHwj {
	border-bottom: 1px dashed var(--line-dot-line);
}

.rmSegment__cities-25M0M {
	order: 2;
}

.rmSegment__cityAirport-33KlY {
	margin-bottom: 0;

	color: var(--text-light);
	line-height: 1.3;
	text-transform: uppercase;
}

.rmSegment__cityInfo-3mI3I {
	font-size: 42px;
	line-height: 1;
}

.rmSegment__cityIata-VLAxf {
	font-weight: 700;
}

.rmSegment__planeIcon-2us3y {
	margin: 0 24px;

	color: var(--text-pale);
}

.rmSegment__flightInfo-1blfX {
	flex-direction: row-reverse;
	order: 1;
}

.rmSegment__flightItem-1V7-w:last-child {
	margin-left: 0;
	margin-right: 40px;
}

.rmSegment__flightTitle-2oZRo {
	margin-bottom: 0;

	color: var(--text-light);
	line-height: 1.3;
	text-transform: uppercase;
}

.rmSegment__flightValue-2TIiS {
	font-size: 42px;
	line-height: 1;
}

.rmSegment__flightItem-1V7-w:last-child .rmSegment__flightValue-2TIiS {
	font-weight: 700;
}

.rmSegment__footer-3Fj1f > div:not(.rmSegment__airlaneName-Q5n-1) {
	display: flex;
}

.rmSegment__airlaneName-Q5n-1 {
	font-size: 15px;
	line-height: 1.3;
	font-weight: 500;
}

.rmSegment__footerItem-sYmIO {
	display: flex;
	flex-direction: column;

	font-size: 0;
	line-height: 1;
}

.rmSegment__footerItem-sYmIO:not(:last-child) {
	margin-right: 28px;
	padding-right: 0;

	border-right: 0;
}

.rmSelectedPassenger-1suUR {
	flex-direction: column;
	align-items: flex-start;

	box-shadow: var(--shdw-card);
}

.rmSelectedPassenger__passenger-28wH- {
	width: 100%;
	padding-right: 0;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
	line-height: 1.3;
}

.rmSelectedPassenger__icon-3NShT {
	display: none;
}

.rmSelectedPassenger__rightSide-2CoFm {
	width: 100%;
	margin-top: 16px;
}

.rmSelectedPassenger__info-1ai6G {
	flex-direction: column;
	align-items: flex-start;
}

.rmSelectedPassenger__infoItem-2Ke2r {
	flex-direction: row;

	color: var(--text-base);
}

.rmSelectedPassenger__infoItem-2Ke2r:not(:last-child) {
	margin-right: 0;
	margin-bottom: 6px;
	padding-right: 0;

	border-right: 0;
}

.rmSelectedPassenger__infoField-3SE4z:not(:first-child):before {
	content: ', ';
}

.rmSelectedPassenger__infoField-3SE4z:not(:last-child) {
	margin-bottom: 0;
}

.rmSelectedPassenger__infoField-3SE4z br {
	display: none;
}

.rmSelectedPassenger__loyaltyLink-wXJec {
	margin-left: 6px;
}

.rmAddPassenger-lp1E6 {
	background-color: var(--white);
	box-shadow: var(--shdw-card);
}

.rmAddPassenger__addButton-2PDGX {
	--cabinet-action: var(--brand-1-0);
	margin: 0;

	font-weight: 700;
	font-size: 15px;
	line-height: 1.3;
}

.rmAddPassenger__addButtonIcon-2-MaB {
	margin-right: 8px;
	width: 32px;
	height: 32px;
}

.rmAddPassenger__addButtonIcon-2-MaB svg {
	width: 100%;
	height: 100%;
}

.rmAddPassenger__passengerIcon-2XSRP {
	display: none;
}

div.rmAddPassenger__formInput-_kuvd {
	margin-right: 20px;

	transform: none;
}

.rmAddPassenger__form-1jTyN .rmAddPassenger__submitButton-ZgbiF {
	--cabinet-action: var(--brand-1-0);
	--cabinet-action--hover: var(--brand-1--1);
	height: 56px;

	border-radius: 4px;

	font-weight: 700;
	font-size: 14px;
	line-height: 56px;
}

/* modal */
.rmLoyaltyNumber__header-3N4vl {
	width: calc(100% - 26px);
	margin-bottom: 22px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 22px;
}

.rmLoyaltyNumber__container-19eKp {
	flex-wrap: wrap;
	grid-row-gap: 24px;
	row-gap: 24px;
}

.rmLoyaltyNumber__inputWrapper-2cKbC {
	flex: 1 1 100%;
	max-width: 100%;
}

.rmLoyaltyNumber__inputWrapper-2cKbC svg {
	display: none;
}

.rmLoyaltyNumber__inputWrapper-2cKbC .rmInput__textField-2FoMt {
	color: var(--text-light);
}

div.rmLoyaltyNumber__input-1_zV7 {
	margin-right: 0;
}

button.rmLoyaltyNumber__button-2m-wH {
	margin-left: auto;

	border-radius: 4px;

	font-weight: 700;

	background: var(--brand-3--1);
}

button.rmLoyaltyNumber__button-2m-wH:hover {
	background: var(--brand-3-0);
}

.rmCheckinCancelModal__text-1gLMO {
	margin-top: 40px;
}

@media screen and (max-width: 600px) {
	.rmLoyaltyNumber__modalScroll-trQK- {
		display: flex;
		align-items: flex-end;
	}

	div.rmLoyaltyNumber__modal-218FC {
		min-height: unset !important;

		border-radius: 0;
	}

	.rmCheckin__segments-2lDnL {
		padding: 12px;

		background: transparent;
	}

	h3.rmCheckin__title-2RZDI {
		margin-top: 0;
		margin-bottom: 12px;

		font-size: 20px;
		line-height: 1.3;
	}

	.rmCheckin__passengers-1o9XT {
		padding: 0 12px 12px;
	}

	.rmCheckin__toolbar-r-YRN {
		padding: 12px;

		box-shadow: var(--shdw-footer);
	}

	button.rmCheckin__toolbarButton-2AMwk {
		position: static;

		width: max-content;
		height: 40px;
		padding: 0 24px;

		border-radius: 4px;

		font-weight: 700;
		font-size: 15px;
		line-height: 40px;

		background-color: var(--brand-3--1);
	}

	button.rmCheckin__toolbarButton-2AMwk:hover {
		background-color: var(--brand-3-0);
	}

	.rmMobileSegment-PPrir {
		--mobileSummaryFlight--background: var(--white);
		display: flex;
		flex-direction: column;

		border-radius: 6px;

		color: var(--text-base);
	}

	.rmMobileSegment__fareGroup-1llJY {
		order: 1;
		justify-content: center;
		padding: 20px 20px 8px;

		border-bottom: 0;
	}

	.rmMobileSegment__flightNumber-13dcV {
		margin-top: 0;

		font-weight: 700;
	}

	.rmMobileSegment__fareGroupInfo-eRVzg span:not(.rmMobileSegment__flightNumber-13dcV) {
		display: none;
	}

	.rmMobileSegment__flightInfo-2c36E {
		order: 2;
		padding: 0 20px 8px;

		border-bottom: 1px dashed var(--line-dot-line);
	}

	.rmMobileSegment__flightInfoIatas-2w1Sq {
		font-size: 44px;
		line-height: 44px;
		font-weight: 700;
	}

	.rmMobileSegment__planeIcon-1_FPs {
		color: var(--text-pale);
	}

	.rmMobileSegment__planeIcon-1_FPs svg {
		width: 44px;
		height: 44px;
	}

	.rmMobileSegment__planeIcon-1_FPs:before,
	.rmMobileSegment__planeIcon-1_FPs:after {
		display: none;
	}

	.rmMobileSegment__flightInfoCities-2oUNk {
		text-decoration: none;
		text-transform: uppercase;
		color: var(--text-light);
		font-size: 13px;
		line-height: 1.3;
	}

	.rmMobileSegment__flightInfoDates-JqP3l {
		margin-bottom: 4px;

		color: var(--text-mid);
		font-weight: 700;
		font-size: 13px;
		line-height: 1.3;
		text-transform: capitalize;
	}

	.rmMobileSegment__flightInfoTime-1yYE3 {
		font-weight: 700;
		font-size: 16px;
		line-height: 1.3;
		color: var(--text-base);
	}

	.rmMobileSegment__flightInfoWeather-2gV7D {
		margin-bottom: 0;
	}

	.rmMobileSegment__arrivalInfo-mSiWC {
		order: 3;
		padding: 8px 20px;
	}

	.rmMobileSegment__arrivalInfoTitle-2hdmZ {
		color: var(--text-light);
		font-size: 13px;
		line-height: 1.3;
		text-transform: uppercase;
	}

	.rmMobileSegment__arrivalInfoItem-1o25O span:not(.rmMobileSegment__arrivalInfoTitle-2hdmZ) {
		color: var(--text-base);
		font-size: 16px;
		font-weight: 700;
		line-height: 1.3;
	}

	.rmSelectedPassenger-1suUR {
		padding: 20px;

		box-shadow: none;
	}

	.rmSelectedPassenger-1suUR:first-of-type {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}

	.rmSelectedPassenger__passenger-28wH- {
		margin-bottom: 0;

		font-size: 16px;
	}

	.rmSelectedPassenger__rightSide-2CoFm {
		margin-top: 18px;
	}

	.rmSelectedPassenger__infoItem-2Ke2r {
		display: block;
	}

	.rmSelectedPassenger__infoField-3SE4z {
		margin-bottom: 0;
	}

	.rmSelectedPassenger__infoField-3SE4z:not(:last-child) {
		margin-bottom: 6px;
	}

	.rmSelectedPassenger__infoField-3SE4z > span:first-child {
		flex: initial;
		margin-right: 5px;
	}

	.rmSelectedPassenger__infoField-3SE4z:not(:first-child):before {
		display: none;
	}

	.rmSelectedPassenger__loyaltyLink-wXJec {
		margin-left: 0;
	}

	.rmAddPassenger-lp1E6 {
		padding: 12px;

		box-shadow: none;
	}

	.rmAddPassenger-lp1E6:last-of-type {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	.rmAddPassenger__addButton-2PDGX {
		justify-content: center;
		width: 100%;
		padding: 12px;

		border-radius: 4px;

		background: var(--brand-1-4);
	}

	.rmAddPassenger__addButtonIcon-2-MaB {
		display: none;
	}

	.rmAddPassenger__addButton-2PDGX > span:after {
		content: '+';

		display: inline-block;
		margin-left: 10px;
	}
}
