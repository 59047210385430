.header-1X6t_ {
	background: var(--brand-1-1);
}

button.saveButton-3o0Rg {
	margin-left: auto;
}

@media screen and (max-width: 600px) {
	.group__field-2ryYU > div {
		margin-top: 27px;
	}
}
