

.header_zeroPrice-26d9I .price__wrapper-3Fxg7 {
	display: none;
}

@media screen and (max-width: 400px) {
	.header-XqiCM {
		grid-gap: 8px;
		gap: 8px;
		padding: 0 16px;

		font-size: 14px;
	}

	.stepIndex__label-9jrjw {
		margin-left: 4px;
	}
}

@media screen and (max-width: 340px) {
	.header-XqiCM {
		font-size: 13px;
	}
}
