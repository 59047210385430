button.paymentForm__detailsButton-2h1Hh {
	max-width: 270px;
	overflow: hidden;

	display: inline-block;

	text-overflow: ellipsis;
}

.paymentForm__details-2S6if {
	max-width: 350px;
}

.checkout__gdsTotalPrice-2ihT- {
	border-right: 1px solid var(--line-separator);

	color: var(--text-mid);
	font-weight: 400;
	font-size: 22px;
	line-height: 1.3;
}

.summary__wrp-2BF2i {
	margin: 16px auto 12px;
}

.summary__header-rPb4U {
	display: none;
}

.messages-2u0kr {
	margin-bottom: 0;
}

.messages_item-2-YF8 {
	margin-bottom: 12px;
}

.desktopFlight__flight-yUyXA {
	display: none;
}

.form-3rgOv > form {
	display: flex;
	flex-direction: column;
}

/* input */
.form-3rgOv div.input-1ah6C:before {
	border-radius: var(--input-border-radius);
}

.form-3rgOv label.textField__root-3PHyx {
	color: var(--text-light);
	font-size: 15px;
	font-weight: 400;
}

.form-3rgOv input.real_input-1Zp0r,
.form-3rgOv div.real_input-1Zp0r {
	font-size: 15px;
	font-weight: 400;
	color: var(--text-base);
}

.form-3rgOv label.textField_filled-3CDnW.textField_focused-BxqAi:not(.label_error-f-d3G),
.form-3rgOv label.textField_filled-3CDnW.textField_focused-BxqAi.label_error-f-d3G,
.form-3rgOv label.textField_standard-33pEn.textField_focused-BxqAi {
	color: var(--text-light);
}

.form-3rgOv label.label_shrink-3YDa- {
	transform: translate(12px, 10px) scale(0.86);
}

.form-3rgOv p.hint_error-nXTiZ {
	color: var(--error-icon);
	font-weight: 400;
	font-size: 13px;

	transform: translateY(100%);
}

.checkout__container-1M_E3 {
	padding-bottom: 120px;
}

button.actionButton-2LQO1 {
	background: var(--brand-3-0);
}

button.actionButton-2LQO1:hover {
	background: var(--brand-3--1);
}

@media screen and (max-width: 600px) {
	.form-3rgOv div.input-1ah6C:before {
		border-radius: 0;

		background: white;
	}

	.form-3rgOv .input_focused-p_efZ.underline-PFoIF input.real_input-1Zp0r,
	.form-3rgOv .input_focused-p_efZ.underline-PFoIF div.real_input-1Zp0r {
		background: var(--brand-1-5);
	}

	.form-3rgOv .input_focused-p_efZ.underline-PFoIF:after {
		border-bottom: 1px solid var(--brand-1-1);
	}

	.form-3rgOv div.input_error-uXn6h:after {
		border-bottom: 1px solid var(--error-icon);
	}

	.form-3rgOv div.input_error-uXn6h:before {
		background: var(--error-bg);
	}

	.checkout__container-1M_E3 {
		padding-bottom: 80px;
	}

	.paymentForm__details-2S6if {
		max-width: 100%;
	}
}
