.fareConditions__modalHeading-3j8De {
	padding: 30px 32px 0;

	color: var(--text-base) !important;
	font-weight: 700 !important;
	font-size: 22px !important;
	line-height: 1.3 !important;
}

.fareConditions__tabsWrapper-1ZADC {
	border-bottom: none;
}

.fareConditions__segmentTabs-itFTy {
	border-bottom: 1px solid var(--line-separator);
}

.fareConditions__fareConditionsSegmentHeading-3Q1RB {
	display: none;
}

.fareConditions__fareConditionsSegmentFareCode-1Nbg9 {
	padding: 28px 32px 0;
}

.fareConditions__tabsWrapper-1ZADC,
.fareConditions__fareConditionsContent-2Vo0L {
	padding: 0 32px;
}

button.fareConditions__tab-3IklN {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.3;
}

.fareConditions__tabActive-IrQ6p {
	color: var(--brand-1-0) !important;
}

.fareConditions__tabIndicator-37lvW {
	background-color: var(--brand-1-0) !important;
}

@media screen and (max-width: 600px) {
	.fareConditions__tabsWrapper-1ZADC,
	.fareConditions__fareConditionsContent-2Vo0L {
		padding: 0 12px;
	}

	.fareConditions__fareConditionsSegmentFareCode-1Nbg9 {
		padding: 20px 12px 0;
	}

	.fareConditions__segmentTabs-itFTy {
		padding: 0;
	}
}
