

.baggageList__item-eYgaT {
	background-color: var(--bg-fill);
}

.baggageList__price-1XzLR {
	color: var(--text-base);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.3;
}

.baggageCount__root-3GUvr {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
