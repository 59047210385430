.rmLogin__header-YuqLW {
	padding: 24px 32px 36px;

	color: var(--text-base);
	font-size: 24px;
}

.rmLogin__headerButtons-1TOOL .rmLogin__prevButton-2xwi3 {
	margin-right: 0;
}

.rmLogin__headerButtons-1TOOL .rmLogin__prevButton-2xwi3 span {
	display: flex;
	justify-content: center;
	align-items: center;
}

.rmLogin__headerButtons-1TOOL .rmLogin__prevButton-2xwi3 path {
	fill: var(--text-pale);
}

.rmLogin__step-3y04K {
	display: none;
}

.rmLogin__verificationForm-20dCi {
	--common-blue: var(--brand-3-0);
	padding: 0 40px;
}

.rmLogin__verificationField-3r_zW {
	flex: 1 1 calc(100% - 180px);
	max-width: calc(100% - 180px);
}

.rmLogin__verificationForm-20dCi .rmLogin__verificationInput-1Ojfm {
	position: relative;

	width: 100%;
}

.rmLogin__verificationInput-1Ojfm > p {
	position: absolute;
	bottom: -21px;
	left: 0;
}

.rmLogin__verificationForm-20dCi .rmLogin__verificationSubmit-Zvqbl {
	--cabinet-action: var(--brand-3-0);
	--cabinet-action--hover: var(--brand-3--1);
	min-width: 160px;
	height: 50px;
	margin-top: 6px;

	border-radius: 6px;

	font-weight: 700;
	font-size: 18px;
	line-height: 50px;
}

.rmLogin__verificationText-3IW_f {
	padding: 0 40px;

	color: var(--text-mid);
	font-size: 16px;
}

.rmLogin__verificationFooter-m5u9O {
	--cabinet-action: var(--text-link);
	--common-gray: var(--text-mid);
	justify-content: flex-start;
	height: auto;
	margin-top: 20px;
	padding: 0 40px 40px;

	background-color: var(--white);
}

button.rmLogin__alternativeItem-2W33- {
	color: var(--text-mid);
}

.rmLogin__wrapper-wMa6A {
	padding: 0 40px 40px;
}

.rmLogin__alternative-3l_Pp {
	color: var(--text-mid);
}

.rmLoginForm-3MIxk {
	height: 56px;
}

button.rmLoginForm__button-2gZ4b {
	--cabinet-action: var(--brand-3-0);
	--cabinet-action--hover: var(--brand-3--1);
	min-width: 160px;
	height: 50px;

	border-radius: 6px;

	font-weight: 700;
	font-size: 18px;
	line-height: 50px;
}

.rmLoginForm__social-GvLGU {
	flex-direction: column;
}

.rmLoginForm__socialButtons-3mMdY {
	margin-left: 0;
	margin-top: 12px;
}

.rmLoginForm__socialText-1Ihwt {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	color: var(--text-light);
}

.rmAccountLogin-3FnIe .rmInput-3aLLY.rmInput_filled-21ggK {
	--input-background: var(--white);
}

.rmAccountLogin-3FnIe .rmInput__real-3Ftjz {
	color: var(--text-base);
}

.rmAccountLogin-3FnIe .rmInput-3aLLY.rmInput_filled-21ggK input.rmInput__real-3Ftjz {
	border-width: 0 0 1px 0;
	border-radius: 0;
}

.rmAccountLogin-3FnIe .rmInput-3aLLY.rmInput_focused-oGl7D input.rmInput__real-3Ftjz {
	background: var(--brand-1-5);
}

.rmAccountLogin-3FnIe .rmInput__textField-2yqsw {
	color: var(--text-light);
}

.rmAccountLogin-3FnIe .rmInput__labelShrink-1ec6M {
	color: var(--gray50);
}

@media screen and (max-width: 600px) {
	.rmAccountLogin-3FnIe {
		position: relative;

		padding-top: 84px;
	}

	.rmLogin__headerButtons-1TOOL {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		height: 56px;
		padding: 0 12px;
	}

	.rmLogin__headerButtons-1TOOL .rmLogin__prevButton-2xwi3 {
		margin-right: auto;
	}

	.rmLoginForm-3MIxk {
		height: auto;
	}

	.rmLoginForm-3MIxk .rmLoginForm__button-2gZ4b {
		margin-top: 20px;
	}

	.rmLogin__wrapper-wMa6A {
		padding: 0;
		height: auto;
	}

	.rmLogin__alternative-3l_Pp {
		margin-top: 40px;
	}

	.rmLogin__header-YuqLW {
		padding: 0;
		margin-bottom: 48px;
	}

	.rmLogin__title-3PkQ0 {
		font-size: 31px;
	}

	.rmLogin__verificationForm-20dCi {
		padding: 0;
	}

	.rmLogin__verificationText-3IW_f {
		padding: 0;
	}

	.rmLogin__verificationField-3r_zW {
		flex: 1 1 100%;
		max-width: 100%;
	}

	.rmLogin__verificationForm-20dCi .rmLogin__verificationSubmit-Zvqbl {
		top: 0;

		margin-top: 20px;
	}

	.rmLogin__verificationFooter-m5u9O {
		padding: 0;
	}
}
