

.additionalServiceBanner__wrapper-Afmfn {
	height: auto;
	padding: 24px 0;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	background: var(--white);
}

.additionalServiceBanner__text-1SNLO {
	flex-direction: row;
	max-width: 100%;

	color: var(--gray80);
	font-size: 22px;
	font-weight: 700;
	line-height: 1;
}

.additionalServiceBanner__disclaimer-1jShq {
	margin-top: 0;
	margin-left: auto;

	color: var(--brand-1-0);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
	text-decoration: none;
}

.additionalServiceBanner__image-31MKa {
	display: none;
}

.additionalServiceBanner__disclaimerIcon-kbAam {
	width: 18px;
	height: 18px;
	margin-right: 6px;

	color: var(--text-pale);
}
