span.checkbox-2bO8N {
	color: var(--text-pale);
}

span.checkbox-2bO8N.checkbox_checked-2UPNZ {
	color: var(--brand-1-0);
}

span.label-1JlBk,
span.label-1JlBk .link-1PbDd {
	font-size: 15px;
	font-weight: 400;
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	span.label-1JlBk .link-1PbDd {
		color: var(--brand-1-0);
	}
}
