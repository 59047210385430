.results__container-2Y8Z1 {
	background-color: var(--bg-fill);
}

.header-IzJ_Q {
	flex-direction: column-reverse;
}

.results__toolbarBack-nxe0A {
	color: var(--text-pale);
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

button.results__toolbarBackButton-18LDQ {
	color: var(--text-pale);

	background-color: var(--bg-fill);
}

button.results__toolbarNextButton-1IYO2 {
	height: 49px;

	border-radius: 6px;

	font-weight: 700;
	font-size: 16px;
	line-height: 49px;

	background-color: var(--brand-3-0);
}

button.results__toolbarNextButton-1IYO2:hover {
	background-color: var(--brand-3--1);
}

.selectedFares__flight-1_NZ6 {
	margin-top: 0;
	margin-bottom: 12px;
}

.selectedFares__flight-1_NZ6:last-child {
	margin-bottom: 0;
}

.calendar__legNumber-RF-95 {
	width: 36px;
	height: 36px;
	margin-right: 16px;

	font-weight: 700;
	font-size: 15px;
	line-height: 36px;

	background: var(--brand-1-0);
}

.calendar__titleText-1ZsT3 {
	color: var(--text-base);
}

.calendar__titleText-1ZsT3 > svg {
	display: none;
}

.calendar__titleText-1ZsT3 > span:first-child {
	margin-right: 6px;
}

.calendar__titleText-1ZsT3 > span:first-child:after {
	content: ' \2014';
}

.calendar__tabs-2JYaL {
	flex-direction: row-reverse;
}

.selectedFares__title-37c-- > svg {
	display: block;
	width: 36px;
	height: 36px;
	margin-right: 16px;
}

.otherDates__container-yIk9Z > svg {
	display: none;
}

button.calendar__diagram-2YmUN {
	color: var(--brand-1-0);
	font-size: 15px;
	font-weight: 500;
	line-height: 1.3;
}

button.calendar__diagram-2YmUN > svg {
	color: var(--brand-1-1);

	opacity: 1;
}

.selectedFares__title-37c--:before {
	background: var(--brand-1-0);
}

.selectedFares__wrapper-Ekosm {
	margin-bottom: 28px;
	padding-bottom: 28px;

	box-shadow: 0 7px 20px rgba(0, 0, 0, 0.03);
	background-color: var(--white);
}

.disclaimer__wrapper-2oWVY {
	color: var(--text-light);
	font-size: 13px;
	font-weight: 400;
	line-height: 1.3;
}

.otherDates__container-yIk9Z {
	padding: 0;

	border: 1px solid var(--success-stroke-hover);

	background-color: var(--success-bg);
}

.otherDates__text-1Iizg {
	padding-right: 15px;
	padding-left: 12px;

	color: var(--success-text);
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
	text-decoration: none;
}

.otherDates__text-1Iizg:after {
	width: 14px;
	height: 14px;
	margin-left: 7px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMDQgMC4zMzMzMTNMNS44MjUwNCAxLjUwODMxTDEwLjQ3NSA2LjE2NjY1SDAuMzMzMzc0VjcuODMzMzFIMTAuNDc1TDUuODI1MDQgMTIuNDkxNkw3LjAwMDA0IDEzLjY2NjZMMTMuNjY2NyA2Ljk5OTk4TDcuMDAwMDQgMC4zMzMzMTNaIiBmaWxsPSIjNDc5NzMyIi8+Cjwvc3ZnPgo=) no-repeat 50% 50%;
	background-size: contain;
	content: '';
}

div.paper-1xeVa {
	min-width: 210px;
}

@media screen and (max-width: 600px) {
	.selectedFares__flight-1_NZ6 {
		box-shadow: 3px 5px 26px rgba(0, 0, 0, 0.1);
	}

	.mobilePriceGraph__dates-1x4_j {
		color: var(--brand-1-0);
	}

	.mobilePriceGraph__dates-1x4_j:before {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNSAySDE2QzE3LjEgMiAxOCAyLjkgMTggNFYxOEMxOCAxOS4xIDE3LjEgMjAgMTYgMjBIMkMwLjg5IDIwIDAgMTkuMSAwIDE4TDAuMDEgNEMwLjAxIDIuOSAwLjg5IDIgMiAySDNWMEg1VjJIMTNWMEgxNVYyWk0yIDE4SDE2VjdIMlYxOFoiIGZpbGw9IiM3MzhEQkUiLz4KPC9zdmc+Cg==) no-repeat 50% 50%;
	}

	button.results__toolbarBackButton-18LDQ {
		position: relative;

		width: 40px;
		height: 40px;
		padding: 0;

		border-radius: 50%;

		color: transparent;
	}

	button.results__toolbarBackButton-18LDQ:before {
		content: '';

		position: absolute;
		top: 50%;
		left: 50%;

		width: 20px;
		height: 20px;

		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkFycm93IC8gIEJhY2siPgo8cGF0aCBpZD0iaWNvbi9uYXZpZ2F0aW9uL2Fycm93X2JhY2tfMjRweCIgZD0iTTE2LjY2NjYgOS4xNjY4M0g2LjUyNDkyTDExLjE4MzMgNC41MDg1TDkuOTk5OTIgMy4zMzM1TDMuMzMzMjUgMTAuMDAwMkw5Ljk5OTkyIDE2LjY2NjhMMTEuMTc0OSAxNS40OTE4TDYuNTI0OTIgMTAuODMzNUgxNi42NjY2VjkuMTY2ODNaIiBmaWxsPSIjMzk1REEzIi8+CjwvZz4KPC9zdmc+Cg==) no-repeat center / contain;
		transform: translate(-50%, -50%);
	}

	button.results__toolbarNextButton-1IYO2 {
		height: 40px;
		padding: 0 24px;

		border-radius: 4px;

		font-size: 15px;
	}

	.results__toolbarBackButton-18LDQ > span {
		display: none;
	}

	.results__container-2Y8Z1 {
		display: flex;
		flex-direction: column;
	}

	.results__inner-1lvrE {
		width: 100%;
		flex: 1;
	}
}
