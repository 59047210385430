.rmOrder__root-3974R {
	color: var(--text-base);
}

.rmOrder__item-Fcvxj:not(:first-child) {
	border-top: 1px solid #eeeeee;
}

.rmOrder__control-2Y2SG {
	color: var(--text-link);
}

.container-3yUB7.type_account-3stEO {
	min-height: 210px;
}

.container-3yUB7:not(.type_account-3stEO) .rmOrder__item-Fcvxj {
	min-height: 66px;
}

button.rmOrder__openOrder-dPxs0 {
	color: var(--brand-1-0);
	font-weight: 700;

	background: var(--brand-1-5);
}

button.rmOrder__openOrder-dPxs0:hover {
	color: var(--brand-1-0);
	font-weight: 700;

	background: var(--brand-1-4);
}

button.rmOrder__changeButton-1g0QA {
	border-color: var(--brand-1-5);
	border-radius: 4px;

	color: var(--text-mid);
	font-weight: 400;
	font-size: 14px;

	background-color: var(--brand-1-5);
}

button.rmOrder__changeButton-1g0QA:hover {
	border-color: var(--brand-1-4);

	color: var(--text-mid);

	background-color: var(--brand-1-4);
}

button.rmOrder__downloadButton-2e7Gn {
	padding-right: 4px;
	padding-left: 16px;

	border-color: var(--brand-1-0);
	border-radius: 4px;

	color: var(--white);

	background-color: var(--brand-1-0);
}

button.rmOrder__downloadButton-2e7Gn:hover {
	border-color: var(--brand-1--1);

	color: var(--white);

	background-color: var(--brand-1--1);
}

button.rmOrder__downloadButton-2e7Gn svg {
	margin-left: 4px;
}

.rmOrder__itemIcon-1aNOS {
	color: var(--text-pale);
}

.rmOrder__itemIcon-1aNOS svg path[fill='#B3B3B3'] {
	fill: var(--text-pale);
}

.rmOrder__bookingRoot-1zOA5.rmOrder__bookingAccount-1_ylZ {
	color: var(--text-mid);
	font-weight: 700;
}

.rmOrder__bookingRoot-1zOA5.rmOrder__bookingReview-aMBSH .rmOrder__orderCode-2x2hU {
	font-size: 16px;
}

.rmOrder__previewInner-lfYEF {
	justify-content: flex-end;
}

@media screen and (max-width: 600px) {
	.rmOrder__item-Fcvxj {
		min-height: 0;
		min-height: initial;
	}

	.container-3yUB7:not(.type_account-3stEO) .rmOrder__item-Fcvxj {
		min-height: 48px;
	}

	.rmOrder__status-1B9RR .rmOrder__payload-2MV9o {
		align-items: center;
	}
}
