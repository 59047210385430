

.loyalty_switch-qINml .toggle-1juFN:after {
	background: var(--line-separator);
}

.loyalty_switch_open-3-ctt .toggle-1juFN:after {
	background: var(--brand-1-0);
}

.switch__label-2UHPl,
.loyalty__desc-MGKY7,
.loyalty__desc-MGKY7 a {
	color: var(--text-light);
	font-size: 15px;
	font-weight: 400;
}

.loyalty__desc-MGKY7 a {
	text-decoration: underline;
}
